import Image from 'next/image';
import { css } from '@bts-web/utils-style-engine';
import { initServerTranslation } from '@bts-web/utils-lokalise';
import { BitpandaLogo } from '../../common/assets/index';
import { HyperLinkButtonBase } from '../../common/components/HyperLinkButtonBase/HyperLinkButtonBase';
import { InfoLayout } from '../../common/layout/InfoLayout/InfoLayout';

const { t } = initServerTranslation();

const descriptionStyles = css({
  color: 'neutrals.text_primary_inverted',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
});

export interface LogoutPageProps {
  searchParams: Promise<Record<string, string>>;
}

const LogoutPage = async ({ searchParams }: LogoutPageProps) => {
  const { auto } = await searchParams;

  const loginUrl = process.env.NEXT_PUBLIC_APP_LOGIN_URL;

  const isAutoLogout = parseInt(auto) === 1;

  const title = isAutoLogout
    ? t('autologout_success_title')
    : t('logout_success_title');

  const subtitle = isAutoLogout
    ? t('autologout_success_subtitle')
    : t('logout_success_subtitle');

  return (
    <InfoLayout primaryText={title} description={subtitle}>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 'large',
          mt: 'auto',
          pb: 'medium',
        })}
      >
        <div
          className={css({
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 'extra_small_2',
          })}
        >
          <p className={descriptionStyles}>{t('powered_by')}</p>
          <Image priority src={BitpandaLogo} alt="Bitpanda" />
        </div>

        {loginUrl && (
          <HyperLinkButtonBase to={loginUrl as string}>
            {t('continue')}
          </HyperLinkButtonBase>
        )}
      </div>
    </InfoLayout>
  );
};

export { LogoutPage };
