import {
  MaximumFractionDigits,
  PrecisionFloat,
  getTargetAmount,
} from '@bts-web/utils-formatting';

export const valueFromAssetToFiat = (
  amount: string,
  currentPrice: PrecisionFloat,
) => {
  const decimalSeparator = amount.includes(',') ? ',' : '.';

  const parsedValue = getTargetAmount({
    targetType: 'FIAT',
    price: currentPrice,
    sourceAmount: {
      value: amount.replace(decimalSeparator, '.'),
      precision: MaximumFractionDigits.FIAT,
    },
  });

  if (!parsedValue) {
    return;
  }

  return {
    value: parsedValue.value.replace('.', decimalSeparator),
    precision: parsedValue.precision,
  };
};
