import { FC } from 'react';
import { ButtonBase } from '../../../../../ButtonBase/ButtonBase';
import { useComposableModal } from '@bts-web/utils-context';
import { TwoFaSectionTitle } from '../../../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../../../shared/TwoFaSectionDescription';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TwoFaSectionButtonsWrapper } from '../../../shared/TwoFaSectionButtonsWrapper';

const FinishedSummaryPage: FC = () => {
  const { onClose } = useComposableModal();

  const { t } = useClientTranslation();

  return (
    <>
      <TwoFaSectionTitle title={`${t('2fa_success_subtitle')}`} />
      <TwoFaSectionDescription textContent={t('2fa_success_body')} />
      <TwoFaSectionButtonsWrapper>
        <ButtonBase onClick={onClose} visual="primary" size="medium">
          {t('done')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { FinishedSummaryPage };
