'use client';

import { useState } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { Sheet } from '../../../common';

interface TaxInformationSheetWrapperProps {
  aboutTaxTextLink?: string;
  taxTitle?: string;
  taxInfoText1?: string;
  taxInfoText2?: string;
}

const primaryContainerStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'title.medium_medium',
  fontWeight: 'title.medium_medium',
  lineHeight: 'title.medium_medium',
  letterSpacing: 'title.medium_medium',
  padding: 'medium',
  borderTopWidth: '1px',
  borderColor: 'neutrals.stroke_secondary',
});

const aboutTextLinkStyles = css({
  display: 'flex',
  gap: 'extra_small_2',
  alignItems: 'center',
  cursor: 'pointer',
  _hover: {
    color: 'neutrals.text_secondary',
    textDecoration: 'underline',
  },
});

const taxInfoTextStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  textAlign: 'left',
});

export const TaxInformationSheetWrapper = ({
  aboutTaxTextLink,
  taxInfoText1,
  taxInfoText2,
  taxTitle,
}: TaxInformationSheetWrapperProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div
        className={primaryContainerStyles}
        aria-label="tax information wrapper"
      >
        <button
          onClick={handleToggle}
          className={aboutTextLinkStyles}
          data-testid="about-test-link"
        >
          {aboutTaxTextLink}
          <Icon icon="info" size="16" />
        </button>
      </div>

      <Sheet
        onOpenChange={handleToggle}
        title={taxTitle}
        isOpened={isOpen}
        id="tax-information-sheet"
      >
        <p className={taxInfoTextStyles}>{taxInfoText1}</p>
        <p className={taxInfoTextStyles}>{taxInfoText2}</p>
      </Sheet>
    </>
  );
};
