import { type AmountFor } from '@bts-web/data-layer/server';
import { MaximumFractionDigits } from '@bts-web/utils-formatting';

export const validateAmountInput = (
  value: string,
  displayType: AmountFor,
  maximumAssetPrecision: number | null = MaximumFractionDigits.ASSET,
  maximumFiatPrecision: number | null = MaximumFractionDigits.FIAT,
): boolean => {
  if (!value) {
    return true;
  }

  if (Number(value) >= Number.MAX_SAFE_INTEGER) {
    return false;
  }

  let validateInputRegExp = new RegExp(
    '^\\d+[.,]?(?:\\d{1,' + maximumFiatPrecision + '})?$',
  );

  if (displayType === 'ASSET') {
    validateInputRegExp = new RegExp(
      '^\\d+[.,]?(\\d{1,' + maximumAssetPrecision + '})?$',
    );
  }

  return validateInputRegExp.test(value);
};
