'use client';

import { TFunction } from 'i18next';
import { PrecisionFloat } from '@bts-web/utils-formatting';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { SuccessModal } from '../../../../common';
import { ModalComponent } from '@bts-web/utils-context';
import { getSavingsPlansTranslations } from '../../../../savings-plans/utils/getSavingsPlansTranslations';

export type SavingsPlanSuccessModalProps = {
  savingsPlanId: string;
  name: string;
  assetName: string;
  assetLogoUrl: string;
  fiatAmount: PrecisionFloat;
  nextRecurrence: string;
};

export const SavingsPlanSuccessModal: ModalComponent<
  SavingsPlanSuccessModalProps
> = ({ name, savingsPlanId, onClose }) => {
  const { t } = useClientTranslation();

  const translations = getSavingsPlansTranslations(t as TFunction);

  return (
    <SuccessModal
      icon="checkmark-circle"
      title={translations.savingsPlanSuccessTitle}
      subtitle={`${name} ${translations.savingsPlanSuccessSubtitle}`}
      continueLink={{
        label: translations.savingsPlanSuccessCheckItOut,
        url: `/savings-plans/${savingsPlanId}`,
      }}
      poweredByLabel={translations.poweredByBitpanda}
      onClose={onClose}
    />
  );
};
