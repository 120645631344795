/**
 * @generated SignedSource<<6c2d70927bbd3ee100eab3024ed37cc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SavingsPlanStatus = "ACTIVE" | "CANCELED";
export type SavingsPlansInput = {
  after?: string | null;
  assetId?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  savingsPlanId?: string | null;
  status?: SavingsPlanStatus | null;
};
export type CountSavingsPlansViewQuery$variables = {
  input?: SavingsPlansInput | null;
};
export type CountSavingsPlansViewQuery$data = {
  readonly savingsPlans: {
    readonly totalCount: number | null;
  } | null;
};
export type CountSavingsPlansViewQuery = {
  response: CountSavingsPlansViewQuery$data;
  variables: CountSavingsPlansViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SavingsPlansConnection",
    "kind": "LinkedField",
    "name": "savingsPlans",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CountSavingsPlansViewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CountSavingsPlansViewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5b073eabc93d1d6dd576c3982d523844",
    "id": null,
    "metadata": {},
    "name": "CountSavingsPlansViewQuery",
    "operationKind": "query",
    "text": "query CountSavingsPlansViewQuery(\n  $input: SavingsPlansInput\n) {\n  savingsPlans(input: $input) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "8a6c4aa363dfb5bea94b66c8b839e81d";

export default node;
