import { css } from '@bts-web/utils-style-engine';
import { FC, useEffect, useRef, useCallback } from 'react';

interface OfferLoaderElementProps {
  duration: number;
  size?: number;
  onComplete?: () => void;
}

export const OfferLoaderElement: FC<OfferLoaderElementProps> = ({
  duration,
  size = 120,
  onComplete,
}) => {
  const progressRef = useRef(100);

  const intervalRef = useRef<number | null>(null);

  const startTimeRef = useRef<number | null>(null);

  const svgRef = useRef<SVGSVGElement | null>(null);

  const updateProgress = useCallback(() => {
    if (!startTimeRef.current) return;

    const elapsedTime = Date.now() - startTimeRef.current;

    const absDuration = Math.abs(duration);

    const newProgress = Math.max(100 - (elapsedTime / absDuration) * 100, 0);

    progressRef.current = newProgress;

    if (svgRef.current) {
      svgRef.current.setAttribute('data-progress', newProgress.toFixed(2));

      const strokeWidth = size / 12;

      const radius = size / 2 - strokeWidth;

      const circumference = 2 * Math.PI * radius;

      const strokeDashoffset = circumference * ((100 - newProgress) / 100);

      const circle = svgRef.current.querySelector('circle');

      if (circle) {
        circle.setAttribute('stroke-dashoffset', strokeDashoffset.toString());
      }
    }

    if (newProgress <= 0) {
      if (intervalRef.current) cancelAnimationFrame(intervalRef.current);

      if (onComplete) onComplete();
    } else {
      intervalRef.current = requestAnimationFrame(updateProgress);
    }
  }, [duration, size, onComplete]);

  const startCountdown = useCallback(() => {
    if (intervalRef.current) cancelAnimationFrame(intervalRef.current);

    progressRef.current = 100;

    startTimeRef.current = Date.now();

    intervalRef.current = requestAnimationFrame(updateProgress);
  }, [updateProgress]);

  useEffect(() => {
    startCountdown();

    return () => {
      if (intervalRef.current) cancelAnimationFrame(intervalRef.current);
    };
  }, [startCountdown, duration]);

  const strokeWidth = size / 12;

  const radius = size / 2 - strokeWidth;

  const circumference = 2 * Math.PI * radius;

  return (
    <svg
      ref={svgRef}
      width={size}
      height={size}
      aria-label="offer loader indicator"
      data-progress="100"
    >
      <circle
        stroke="blue"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset="0"
        r={radius}
        cx={size / 2}
        cy={size / 2}
        className={css({
          stroke: 'brand.fill_secondary',
        })}
      />
    </svg>
  );
};
