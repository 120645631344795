import { AccentTradeControllerProps } from '../types';
import { getTradeTranslations } from '../../utils/getTradeTranslations';
import { NavigationBackButton, NavigationButton } from '../../../navigation';
import { NavigationBar } from '../../../common';

const AcceptTradeController = async ({
  offerData,
  variant,
  children,
  translationUtil,
  currency,
}: AccentTradeControllerProps) => {
  const translations = getTradeTranslations(translationUtil);

  const ACCEPT_TRADE_TITLES = {
    buy: translations.buy,
    sell: translations.sell,
  };

  return (
    <>
      <NavigationBar
        title={ACCEPT_TRADE_TITLES[variant]}
        NavigationLeftSlot={<NavigationBackButton />}
        NavigationRightSlot={
          <NavigationButton icon="dismiss" to="/assets/portfolio" />
        }
      />

      {children &&
        children({
          initialOfferData: offerData,
          translations,
          currency,
        })}
    </>
  );
};

export { AcceptTradeController };
