import RaiffeisenLogo from './RaiffeisenLogo.svg';
import BitpandaLogo from './BitpandaLogo.svg';
import GlobalCoinLogo from './global-coin.svg';
import GlobalETFLogo from './global-etf.svg';
import GlobalETCLogo from './global-etc.svg';
import GlobalMetalLogo from './global-metal.svg';
import GlobalStockLogo from './global-stock.svg';
import defaultAssetImg from './default-image/default-asset.svg';
import { defaultAssetInlineEncoded } from './default-image/default-asset-inline.svg';
import defaultAssetPngImg from './default-image/default_asset_image.png';

export {
  RaiffeisenLogo,
  BitpandaLogo,
  GlobalCoinLogo,
  GlobalETFLogo,
  GlobalETCLogo,
  GlobalMetalLogo,
  GlobalStockLogo,
  defaultAssetInlineEncoded,
  defaultAssetImg,
  defaultAssetPngImg,
};
