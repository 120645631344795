import { css } from '@bts-web/utils-style-engine';

export const submitButtonWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 'extra_small_4',
  width: '100%',
  textAlign: 'center',
  pt: 'extra_small',
  pb: 'medium',
  mt: 'auto',
};

export const submitButtonWrapperAndButtonProperStyles = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mx: 'auto',
  maxWidth: '440px',
});
