const links = {
  bitpandaTerms: 'https://www.bitpanda.com/en/legal',
  generalTC: 'https://www.bitpanda.com/en/legal',
  cryptoTC: 'https://www.bitpanda.com/en/legal',
  metalsTC: 'https://www.bitpanda.com/en/legal',
  derivativesTC: 'https://www.bitpanda.com/en/legal',
  executionPolicy: 'https://www.bitpanda.com/en/legal',
  bitpandaGroupPolicy: 'https://www.bitpanda.com/en/legal',
  bitpandaFinancialServicesPolicy: 'https://www.bitpanda.com/en/legal',
  bitPandaAcademy: 'https://www.bitpanda.com/academy/de/stufen/alle',
  detailedInformationLink:
    'https://cdn.bitpanda.com/media/documents/securities/de-kundeninformationsdokument.pdf',
  riskDisclosureLinkDE:
    'https://cdn.bitpanda.com/terms-and-conditions/cost-transparency-crypto-bitpanda-de-latest.pdf',

  riskDisclosureLinkEN:
    'https://cdn.bitpanda.com/terms-and-conditions/cost-transparency-crypto-bitpanda-en-latest.pdf',
};

export { links };
