import { SkeletonElement } from '../../../common/components';
import { css } from '@bts-web/utils-style-engine';

export const MobileNavWithTradeSkeleton = () => (
  <div
    className={css({
      alignItems: 'flex-end',
      backgroundColor: 'screen_background.primary',
      borderTopWidth: '1px',
      borderColor: 'neutrals.divider',
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      paddingX: 'extra_small',
      position: 'fixed',
      zIndex: 3,
      borderRadius: '16px',
      borderTop: 'none',
      boxShadow:
        '0px 10px 20px 0px rgba(0, 0, 0, 0.05), 0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
      gap: 'small',
      left: '50%',
      marginBottom: 'small',
      transform: 'translate(-50%, 0)',
      width: 'auto',
    })}
    data-testid="mobile-nav-bar-skeleton"
  >
    <SkeletonElement
      width="44px"
      height="44px"
      className={css({ margin: 'extra_small_2' })}
    />
    <SkeletonElement
      width="44px"
      height="44px"
      className={css({ margin: 'extra_small_2' })}
    />{' '}
    <SkeletonElement
      width="44px"
      height="44px"
      className={css({ margin: 'extra_small_2' })}
    />
  </div>
);
