import {
  MaximumFractionDigits,
  intlAssetValueFormatting,
} from '@bts-web/utils-formatting';
import { type AmountFor } from '@bts-web/data-layer/server';

export const getAmountInputPlaceHolder = ({
  locale,
  currency,
  displayType,
  symbol,
}: {
  locale: string;
  displayType: AmountFor;
  currency: string;
  symbol: string;
}): string => {
  if (displayType === 'FIAT') {
    return intlAssetValueFormatting(0.0, {
      locale,
      currency,
      minimumFractionDigits: MaximumFractionDigits.FIAT,
    });
  }

  const placeholderAssetValue = intlAssetValueFormatting(0.0, {
    locale,
    minimumFractionDigits: MaximumFractionDigits.ASSET,
    notation: 'standard',
  });

  return `${placeholderAssetValue} ${symbol}`;
};
