'use client';

import { FC } from 'react';
import { TwoFaSectionTitle } from '../TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../TwoFaSectionDescription';
import { TwoFaCodeSubmitBox } from '../TwoFaCodeSubmitBox/TwoFaCodeSubmitBox';
import { TTwoFaCommonProps } from '../../SetupTwoFaModal/types';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TwoFaCodeSetupActionVariant } from '../../../../../../auth';

const InputCodePage: FC<
  Pick<TTwoFaCommonProps, 'onContinue' | 'bindRecoveryCodes'> & {
    actionVariant: TwoFaCodeSetupActionVariant;
  }
> = ({ onContinue, bindRecoveryCodes, actionVariant }) => {
  const { t } = useClientTranslation();

  return (
    <>
      <TwoFaSectionTitle title={t('corp_onboarding_email_q_title')} />
      <TwoFaSectionDescription textContent={t('authentication_text')} />
      <TwoFaCodeSubmitBox
        bindRecoveryCodes={bindRecoveryCodes}
        onContinue={onContinue}
        actionVariant={actionVariant}
      />
    </>
  );
};

export { InputCodePage };
