import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';

export const SavingsPlanDateConfigItem = ({
  label,
  value,
  hasIndicator,
}: {
  label: string;
  value?: string;
  hasIndicator?: boolean;
}) => {
  return (
    <div className={css({ display: 'flex', alignItems: 'center' })}>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          py: 'medium',
          gap: 'extra_small_2',
          flex: 'auto',
          alignItems: 'flex-start',
          '& svg': {
            color: 'neutrals.text_secondary',
          },
        })}
      >
        <span
          className={css({
            fontSize: 'caption.medium_medium',
            fontWeight: 'caption.medium_medium',
            letterSpacing: 'caption.medium_medium',
            lineHeight: 'caption.medium_medium',
            color: 'neutrals.text_secondary',
          })}
        >
          {label}
        </span>

        <span
          className={css({
            fontSize: 'title.large',
            fontWeight: 'title.large',
            letterSpacing: 'title.large',
            lineHeight: 'title.large',
            color: 'neutrals.text_primary',
          })}
        >
          {value}
        </span>
      </div>

      {hasIndicator && <Icon icon="chevron-right" size="16" />}
    </div>
  );
};
