import { formatDateToYmd } from '@bts-web/utils-formatting';

// returns YEAR-MONTH-DAY of the Nth day provided as argument (current day + nth days).
export const getYmdForNthDayFromToday = (nth: number): string => {
  const today = new Date();

  const dayAfterTomorrow = new Date(today.setDate(today.getDate() + nth));

  return formatDateToYmd(dayAfterTomorrow);
};

// Returns YEAR-MONTH-DAY of today + current month days length + nth days.
export const getYmdForCurrentMonthLengthAndNthDays = (nth: number): string => {
  const today = new Date();

  const year = today.getFullYear();

  const month = today.getMonth();

  const numberOfCurrentMonthDays = new Date(year, month + 1, 0).getDate();

  const lastDay = new Date(
    today.setDate(today.getDate() + numberOfCurrentMonthDays + nth),
  );

  return formatDateToYmd(lastDay);
};
