'use client';

import { FailureModal } from '../../../../common';
import { getSavingsPlansTranslations } from '../../../../savings-plans/utils/getSavingsPlansTranslations';
import { TFunction } from 'i18next';
import { ModalComponent } from '@bts-web/utils-context';
import { useClientTranslation } from '@bts-web/utils-lokalise';

export type SavingsPlansFailedModalProps = {
  assetName: string;
  assetId: string;
  assetLogoUrl: string;
  description?: string;
};

export const SavingsPlanFailureModal: ModalComponent<
  SavingsPlansFailedModalProps
> = ({ assetName, assetLogoUrl, assetId, description, onClose }) => {
  const { t } = useClientTranslation();

  const translations = getSavingsPlansTranslations(t as TFunction);

  return (
    <FailureModal
      logo={{
        url: assetLogoUrl,
        name: assetName,
      }}
      title={translations.savingsPlanFailedTitle}
      description={description || translations.savingsPlanFailedSubtitle}
      tryAgainLink={{
        label: translations.tryAgain,
        url: `/trade/standing-buy/${assetId}`,
      }}
      cancelLink={{
        label: translations.cancelTransaction,
        url: '/assets/portfolio',
      }}
      poweredByLabel={translations.poweredByBitpanda}
      onClose={onClose}
    />
  );
};
