import { FC, ReactNode, useState } from 'react';
import { AssetType, TradeScreenTranslations } from '../../../../utils';
import { AcceptTradeControllerVariants } from '../../../types';
import { AcceptOfferMetalsTerms } from './components/AcceptOfferMetalsTerms';
import { AcceptOfferStockEtfEtcEligibleTerms } from './components/AcceptOfferStockEtfEtcEligibleTerms';
import { AcceptOfferStockEtfEtcNonEligibleTerms } from './components/AcceptOfferStockEtfEtcNonEligibleTerms';
import { useClientUserInfo } from '../../../../../common/userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo';

export type AcceptBuyTradeDisclaimerWrapperProps = {
  translations: TradeScreenTranslations;
  variant: AcceptTradeControllerVariants;
  legalUrl: string;
  assetType: AssetType;
  children?: (props: {
    areTermsAgreedTo: boolean;
    isDisclaimerLoading: boolean;
  }) => ReactNode;
};

export const AcceptBuyTradeDisclaimerWrapper: FC<
  AcceptBuyTradeDisclaimerWrapperProps
> = ({ translations, variant, children, legalUrl, assetType }) => {
  const isCrypto = assetType === 'CryptoAsset';

  const isMetalAsset = assetType === 'MetalAsset';

  const isNotCryptoOrMetal = !isCrypto && !isMetalAsset;

  const noVerificationRequired = isCrypto || variant !== 'buy';

  const userData = useClientUserInfo();

  const [disclaimerAgreed, setDisclaimerAgreed] = useState(
    noVerificationRequired ? true : false,
  );

  const toggleDisclaimerAgreement = () => {
    setDisclaimerAgreed((state) => !state);
  };

  const isUserAppropriate =
    userData &&
    userData.appropriateness !== 'NOT_APPROPRIATE' &&
    userData.appropriatenessRequiresTestCompletion === false &&
    userData.appropriatenessRequiresTestResubmission === false;

  return (
    <>
      {isMetalAsset && !noVerificationRequired ? (
        <AcceptOfferMetalsTerms
          translations={{
            acceptMetalTerms: translations.acceptMetalTerms,
            poweredByBpfs: translations.poweredByBpfs,
          }}
          setTermsAgreed={toggleDisclaimerAgreement}
          termsAgreed={disclaimerAgreed}
        />
      ) : isNotCryptoOrMetal && isUserAppropriate && !noVerificationRequired ? (
        <AcceptOfferStockEtfEtcEligibleTerms
          legalDocumentUrl={legalUrl}
          translations={translations}
          setTermsAgreed={toggleDisclaimerAgreement}
          termsAgreed={disclaimerAgreed}
        />
      ) : isNotCryptoOrMetal &&
        !isUserAppropriate &&
        !noVerificationRequired ? (
        <AcceptOfferStockEtfEtcNonEligibleTerms
          legalDocumentUrl={legalUrl}
          translations={translations}
          setTermsAgreed={setDisclaimerAgreed}
        />
      ) : null}

      {children &&
        children({
          areTermsAgreedTo: disclaimerAgreed,
          isDisclaimerLoading: false,
        })}
    </>
  );
};
