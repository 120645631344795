import { SkeletonElement } from '../../../common/components';
import { css } from '@bts-web/utils-style-engine';

export const PortfolioAreaChartSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'flex-end',
      justifyContent: 'center',
      position: 'relative',
      gap: '3px',
      borderColor: 'neutrals.stroke_secondary',
      borderBottomWidth: '1px',
    })}
    data-testid="portfolio-area-chart-skeleton"
  >
    <div
      className={css({
        width: '100%',
        paddingX: 'medium',
      })}
    >
      <SkeletonElement width="100%" height="410px" />
    </div>
    <div
      className={css({
        justifyContent: 'flex-start',
        gap: 'extra_small_2',
        background: 'none',
        paddingX: 'medium',
        display: 'flex',
      })}
    >
      <SkeletonElement width="48px" height="34px" />
      <SkeletonElement width="48px" height="34px" />
      <SkeletonElement width="48px" height="34px" />
      <SkeletonElement width="48px" height="34px" />
      <SkeletonElement width="48px" height="34px" />
      <SkeletonElement width="48px" height="34px" />
    </div>
  </div>
);
