/**
 * @generated SignedSource<<aa0b0d71ae7d9fda074c1f1dc923d81e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SavingsPlanFrequency = "BIWEEKLY" | "MONTHLY" | "WEEKLY";
export type SavingsPlanStatus = "ACTIVE" | "CANCELED";
export type PreAcceptSavingsPlanInput = {
  id: string;
};
export type PreAcceptSavingsPlanMutation$variables = {
  input?: PreAcceptSavingsPlanInput | null;
};
export type PreAcceptSavingsPlanMutation$data = {
  readonly preAcceptSavingsPlan: {
    readonly asset: {
      readonly __typename: string;
      readonly id: string;
      readonly logoUrl: string | null;
      readonly name: string | null;
      readonly priceBuy: PrecisionFloat | null;
      readonly symbol: string | null;
    } | null;
    readonly day: number | null;
    readonly fiatAmount: PrecisionFloat | null;
    readonly frequency: SavingsPlanFrequency | null;
    readonly id: string;
    readonly name: string | null;
    readonly nextRecurrence: any | null;
    readonly notificationDate: any | null;
    readonly status: SavingsPlanStatus | null;
  } | null;
};
export type PreAcceptSavingsPlanMutation = {
  response: PreAcceptSavingsPlanMutation$data;
  variables: PreAcceptSavingsPlanMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SavingsPlan",
    "kind": "LinkedField",
    "name": "preAcceptSavingsPlan",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "day",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nextRecurrence",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notificationDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logoUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priceBuy",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fiatAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "frequency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreAcceptSavingsPlanMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreAcceptSavingsPlanMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "2b724eff41bed9cd3d36897912a7cd2b",
    "id": null,
    "metadata": {},
    "name": "PreAcceptSavingsPlanMutation",
    "operationKind": "mutation",
    "text": "mutation PreAcceptSavingsPlanMutation(\n  $input: PreAcceptSavingsPlanInput\n) {\n  preAcceptSavingsPlan(input: $input) {\n    id\n    day\n    name\n    nextRecurrence\n    notificationDate\n    asset {\n      __typename\n      id\n      name\n      symbol\n      logoUrl\n      priceBuy\n    }\n    fiatAmount\n    frequency\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "39a377427b53b0b1417eef7ee1c8dc11";

export default node;
