import {
  dataDogErrorHelperRoot,
  DatadogErrorLevels,
} from '@bts-web/utils-next-infrastructure';

type TDatadogHelperParams = {
  errorMessage: string;
  errorSeverity: DatadogErrorLevels;
};

export const datadogErrorHelper = ({
  errorMessage,
  errorSeverity,
}: TDatadogHelperParams) => {
  dataDogErrorHelperRoot(new Error(errorMessage), {
    errors: [errorMessage],
    environment: process.env.NEXT_PUBLIC_DATADOG_ENV,
    severity: errorSeverity,
  });
};
