/**
 * @generated SignedSource<<01c399ab16a452aa52b86a11621fa7bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Appropriateness = "APPROPRIATE" | "NOT_APPROPRIATE" | "NOT_EXPERIENCED" | "UNKNOWN";
export type UserLanguage = "DE" | "EN" | "ES" | "FR" | "IT" | "PL" | "TR";
export type UpdateUserInput = {
  aml?: ReadonlyArray<UserAML> | null;
  appropriateness?: ReadonlyArray<UserAppropriateness> | null;
  isTaxEligible?: boolean | null;
  language?: UserLanguage | null;
  termsAndConditions?: ReadonlyArray<string> | null;
};
export type UserAML = {
  answerId: string;
  questionId: string;
};
export type UserAppropriateness = {
  answerId: string;
  questionId: string;
  value?: string | null;
};
export type UpdateUserMutation$variables = {
  input?: UpdateUserInput | null;
};
export type UpdateUserMutation$data = {
  readonly updateUser: {
    readonly accountLevelId?: string;
    readonly active?: boolean;
    readonly appropriateness?: Appropriateness | null;
    readonly appropriatenessRequiresTestCompletion?: boolean | null;
    readonly appropriatenessRequiresTestResubmission?: boolean | null;
    readonly approved?: boolean;
    readonly blacklisted?: boolean;
    readonly id: string;
    readonly language?: UserLanguage;
    readonly onboarded: boolean;
    readonly tradingActive?: boolean;
    readonly tradingDisabledReason?: string | null;
    readonly verificationReason?: string | null;
    readonly verificationStatus?: string;
    readonly verified?: boolean;
    readonly withdrawalsActive?: boolean;
  } | null;
};
export type UpdateUserMutation = {
  response: UpdateUserMutation$data;
  variables: UpdateUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onboarded",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appropriateness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appropriatenessRequiresTestCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appropriatenessRequiresTestResubmission",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blacklisted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountLevelId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tradingActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tradingDisabledReason",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "type": "IndividualUser",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approved",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "withdrawalsActive",
      "storageKey": null
    }
  ],
  "type": "BusinessUser",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd284bf1e9707fe2bdb10e9934c668a1",
    "id": null,
    "metadata": {},
    "name": "UpdateUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserMutation(\n  $input: UpdateUserInput\n) {\n  updateUser(input: $input) {\n    __typename\n    id\n    onboarded\n    ... on IndividualUser {\n      appropriateness\n      appropriatenessRequiresTestCompletion\n      appropriatenessRequiresTestResubmission\n      active\n      blacklisted\n      verified\n      verificationStatus\n      verificationReason\n      accountLevelId\n      tradingActive\n      tradingDisabledReason\n      language\n    }\n    ... on BusinessUser {\n      language\n      approved\n      withdrawalsActive\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a435d2412bd9813120043e0b2bb51086";

export default node;
