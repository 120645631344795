'use client';

import { Icon } from '@bts-web/design-system/component/icon';
import React, { ReactNode, useCallback, useState } from 'react';
import { Z_INDEX_LIBRARY } from '@bts-web/utils-style-engine';
import { isThisMonth, isPast, format } from 'date-fns';
import { DayPicker, DayPickerProps } from 'react-day-picker';

export interface DatePickerProps {
  internal: {
    className: string;
    overlayClassName: string;
    contentClassName: string;
  };
  external: {
    zIndex?: number;
    cancelLabel: string;
    disabled?: DayPickerProps['disabled'];
    confirmLabel: string;
    contentStyle?: React.CSSProperties;
    value: string | undefined;
    disablePast?: boolean;
    onChange: (date: string) => void;
    children: ReactNode;
  };
}

const DatePicker = ({
  className,
  cancelLabel,
  confirmLabel,
  overlayClassName,
  contentClassName,
  contentStyle,
  children,
  disabled,
  onChange,
  zIndex = Z_INDEX_LIBRARY.MODAL,
  value = format(new Date(), 'yyyy-MM-dd'),
  disablePast,
}: DatePickerProps['internal'] & DatePickerProps['external']) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleConfirm = () => {
    if (selectedDate) {
      const formattedDate = format(selectedDate, 'yyyy-MM-dd');

      onChange(formattedDate);
    }

    setSelectedDate(undefined);

    setIsOpen(false);
  };

  const handleCancel = () => {
    setSelectedDate(undefined);

    setIsOpen(false);
  };

  const isPastDate = useCallback(
    (date: Date | number) => disablePast && isPast(date),
    [disablePast],
  );

  const dateValue = new Date(value);

  return (
    <>
      <button
        type="button"
        data-element="date-picker-open-trigger"
        onClick={() => setIsOpen(true)}
      >
        {children}
      </button>

      {isOpen && (
        <>
          <div
            className={overlayClassName}
            data-testid="date-picker-overlay"
            style={{ zIndex }}
          />

          <div
            style={{ zIndex, ...contentStyle }}
            data-testid="date-picker-content"
            className={contentClassName}
          >
            <DayPicker
              mode="single"
              className={className}
              selected={selectedDate || dateValue}
              disabled={disabled}
              onSelect={setSelectedDate}
              footer
              required
              defaultMonth={dateValue}
              components={{
                CaptionLabel: ({ children }) => {
                  return (
                    <div
                      data-element="date-picker-caption"
                      data-testid="date-picker-caption"
                    >
                      {children}
                    </div>
                  );
                },
                Nav: ({ onNextClick, previousMonth, onPreviousClick }) => {
                  const now = new Date();

                  const earliestDate = previousMonth
                    ? new Date(
                        Math.min(now.getTime(), previousMonth?.getTime()),
                      )
                    : now;

                  const canPrev = earliestDate
                    ? !isPastDate(earliestDate) || isThisMonth(earliestDate)
                    : false;

                  return (
                    <div
                      data-element="date-picker-nav"
                      data-testid="date-picker-nav"
                    >
                      {onPreviousClick && (
                        <button
                          type="button"
                          onClick={onPreviousClick}
                          disabled={!canPrev}
                          tabIndex={1}
                        >
                          <Icon icon="chevron-left" size="24" theme="regular" />
                        </button>
                      )}

                      {onNextClick && (
                        <button type="button" onClick={onNextClick}>
                          <Icon
                            icon="chevron-right"
                            size="24"
                            theme="regular"
                          />
                        </button>
                      )}
                    </div>
                  );
                },
                Footer: () => {
                  return (
                    <div data-element="date-picker-footer">
                      <button
                        type="button"
                        onClick={handleCancel}
                        data-testid="date-picker-cancel"
                      >
                        {cancelLabel}
                      </button>

                      <button
                        type="button"
                        onClick={handleConfirm}
                        disabled={!selectedDate}
                        data-testid="date-picker-confirm"
                      >
                        {confirmLabel}
                      </button>
                    </div>
                  );
                },
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export { DatePicker };
