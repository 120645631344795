import { css } from '@bts-web/utils-style-engine';
import { TradeInputErrorType } from '../MainTradeForm/hooks/useTradeInput';
import { TradeInnerTranslatedValues } from '../types';
import { Icon } from '@bts-web/design-system/component/icon';

const errorElementStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: 'extra_small_3',
  color: 'negative.text_primary',
  fontSize: 'caption.small_light',
  fontWeight: 'caption.small_light',
  letterSpacing: 'caption.small_light',
  lineHeight: 'caption.small_light',
};

const MainTradeFormErrorBox = ({
  errors,
  translatedValues,
}: {
  errors: TradeInputErrorType;
  translatedValues?: TradeInnerTranslatedValues;
}) => {
  return (
    <>
      {errors.tooHigh && (
        <span
          className={css(errorElementStyles)}
          data-testid="special-input-field__error"
          data-element="error"
          aria-label="error too high"
        >
          <Icon icon="dismiss-circle" theme="regular" size="12" />

          {translatedValues && translatedValues.errorTooHigh}
        </span>
      )}

      {errors.tooLow && (
        <span
          className={css(errorElementStyles)}
          data-testid="special-input-field__error"
          data-element="error"
          aria-label="error too low"
        >
          <Icon icon="dismiss-circle" theme="regular" size="12" />

          {translatedValues && translatedValues.errorTooLow}
        </span>
      )}

      {errors.customError && (
        <span
          className={css(errorElementStyles)}
          data-testid="special-input-field__error"
          data-element="error"
          aria-label="error generic"
        >
          <Icon icon="dismiss-circle" theme="regular" size="12" />

          {errors.customError}
        </span>
      )}
    </>
  );
};

export { MainTradeFormErrorBox };
