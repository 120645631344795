'use client';

import { useContext, createContext, useState, PropsWithChildren } from 'react';
import { ReportType, ReportsViewQuery$data } from '@bts-web/data-layer/server';
import { getReports } from '@bts-web/core-features/common';
import { REPORTS_LIST_PAGE_SIZE } from '@bts-web/core-features/config';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useHandleGqlErrorsWithSnackbar } from '../../utils-relay/src/lib/handleGqlErrors/handleGqlErrorsWithSnackbar';

export interface ReportsContextProps {
  reports: ReportsViewQuery$data['reports'];
  isLoading: boolean;
  selectedReportsType: ReportType | 'ALL';
  setCurrentReportsType: (reportType: ReportType) => void;
  loadMoreReports: () => void;
}

const ReportsContext = createContext<ReportsContextProps | null>(null);

export function useReportsContext() {
  const contextValue = useContext(ReportsContext);

  if (contextValue === null) {
    throw new Error(
      'useReportsContext must be used within a ReportsContextProvider',
    );
  }

  return contextValue;
}

export const ReportsContextProvider = ({
  children,
  initialReportsData,
}: PropsWithChildren<{
  initialReportsData: ReportsContextProps['reports'];
}>) => {
  const [reports, setReports] = useState<ReportsContextProps['reports']>(
    initialReportsData || null,
  );

  const [selectedReportsType, setSelectedReportsType] = useState<
    ReportType | 'ALL'
  >('ALL');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const setCurrentReportsType = async (
    reportType: ReportsContextProps['selectedReportsType'],
  ) => {
    setSelectedReportsType(reportType);

    setIsLoading(true);

    try {
      const reportsResponse = await getReports({
        first: REPORTS_LIST_PAGE_SIZE,
        ...(reportType !== 'ALL' && { type: reportType }),
      });

      if (reportsResponse.errors) {
        setSelectedReportsType(selectedReportsType);

        processErrors(reportsResponse.errors);

        return;
      }

      setReports(reportsResponse.data.reports);
    } catch (error) {
      setSelectedReportsType(selectedReportsType);

      if (typeof error === 'string') {
        processErrors([error] as string[]);
      } else {
        processErrors(error as unknown as string[]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreReports = async () => {
    const currentPageCursor = reports?.pageInfo?.endCursor;

    try {
      const response = await getReports({
        first: REPORTS_LIST_PAGE_SIZE,
        ...(selectedReportsType !== 'ALL' && { type: selectedReportsType }),
        after: currentPageCursor,
      });

      if (response.errors) {
        processErrors(response.errors);

        return;
      }

      const loadedReports = response?.data?.reports;

      if (loadedReports) {
        setReports({
          ...reports,
          edges: [...(reports?.edges || []), ...(loadedReports?.edges || [])],
          pageInfo: loadedReports.pageInfo,
        } as ReportsContextProps['reports']);
      }
    } catch (error) {
      if (typeof error === 'string') {
        processErrors([error] as string[]);
      } else {
        processErrors(error as unknown as string[]);
      }
    }
  };

  return (
    <ReportsContext.Provider
      value={{
        isLoading,
        reports,
        selectedReportsType,
        loadMoreReports,
        setCurrentReportsType,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};
