import { memo } from 'react';
import { useTradeForm } from '../hooks/useTradeForm/useTradeForm';
import { TradeFormProps } from '../types';

export const HiddenInputs = memo(
  ({
    formState,
    assetDetails,
    staticValues,
  }: {
    formState: ReturnType<typeof useTradeForm>['formState'];
    assetDetails: TradeFormProps['assetDetails'];
    staticValues: TradeFormProps['staticValues'];
  }) => (
    <>
      <input
        type="hidden"
        data-testid="trade-input-amount"
        name="amount"
        value={formState.inputValue}
      />
      <input
        type="hidden"
        data-testid="trade-input-amountFor"
        name="amountFor"
        value={formState.displayType}
      />
      <input
        type="hidden"
        data-testid="trade-savingsPlanRecurringDate"
        name="savingsPlanRecurringDate"
        value={formState.savingsPlanRecurringDate}
      />
    </>
  ),
);
