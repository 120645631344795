import { FC } from 'react';
import { Drawer } from '../../Drawer/Drawer';
import { DrawerContent } from '../../../common/components/DrawerContent/DrawerContent';
import { UserTopBarMenu } from '../DesktopTopHeaderBar/subcomponents/UserTopBarMenu/UserTopBarMenu';
import {
  initServerTranslation,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';
import { Icon } from '@bts-web/design-system/component/icon';

const { t } = initServerTranslation();

const MobileProfileMenu: FC = async () => {
  await updateServerLanguage();

  const translations = {
    frequentlyAskedQuestions: t('frequently_asked_questions'),
    customerSupport: t('customer_support'),
    termsAndConditions: t('terms_and_conditions'),
    generalLogout: t('general_logout'),
    profile: t('general_profile'),
    twoFaWelcomeTitle: t('2fa_welcome_title'),
    twoFaResetTitle: t('2fa_reset_title'),
    reportsAndStatements: t('reports_and_statements'),
  };

  return (
    <Drawer
      id="mobile-profile-drawer"
      position="bottom"
      buttonTriggerNode={
        <button>
          <Icon icon="person" size="20" theme="regular" />
        </button>
      }
      contentNode={
        <DrawerContent title={translations.profile} ariaLabel="profile menu">
          <UserTopBarMenu translatedValues={translations} />
        </DrawerContent>
      }
    />
  );
};

export { MobileProfileMenu };
