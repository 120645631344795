import React, { forwardRef, ReactNode } from 'react';

export interface BreadcrumbsProps {
  internal: {
    className?: string;
  };
  external: {
    children: React.ReactNode;
    separator?: string;
  };
}

interface ListSeparatorProps {
  separator: string;
}

const ListSeparator = ({ separator }: ListSeparatorProps) => (
  <li aria-hidden data-element="separator" data-testid="separator">
    {separator}
  </li>
);

const insertSeparators = (items: ReactNode[], separator: string) =>
  items.reduce(
    (acc: ReactNode[], current, index) => [
      ...acc,
      ...(index < items.length - 1
        ? [
            current,
            <ListSeparator key={`separator-${index}`} separator={separator} />,
          ]
        : [current]),
    ],
    [],
  );

export const Breadcrumbs = forwardRef<
  HTMLElement,
  BreadcrumbsProps['internal'] & BreadcrumbsProps['external']
>((props, rootRef) => {
  const { children, separator = '/', ...restProps } = props;

  const allItems = React.Children.toArray(children)
    .filter((child) => {
      return React.isValidElement(child);
    })
    .map((child, index) => <li key={`child-${index}`}>{child}</li>);

  return (
    <nav
      ref={rootRef}
      {...restProps}
      aria-label="breadcrumbs"
      data-testid="breadcrumbs"
    >
      <ol>{insertSeparators(allItems, separator)}</ol>
    </nav>
  );
});
