import { AcceptTermToggle } from '../../../../../../common/components/AcceptTermToggle/AcceptTermToggle.use-client';
import { css } from '@bts-web/utils-style-engine';
import { TradeScreenTranslations } from '../../../../../utils';
import { LegalDocumentsTermLabel } from './LegalDocumentsTermLabel';

interface AcceptOfferStockEtfEtcEligibleTermsProps {
  translations: TradeScreenTranslations;
  termsAgreed: boolean;
  legalDocumentUrl: string;
  setTermsAgreed: () => void;
}

const AcceptOfferStockEtfEtcEligibleTerms = ({
  translations,
  termsAgreed,
  legalDocumentUrl,
  setTermsAgreed,
}: AcceptOfferStockEtfEtcEligibleTermsProps) => {
  return (
    <div
      className={css({
        display: 'flex',
        gap: 'small',
        alignItems: 'flex-start',
      })}
      data-testid="buy-terms-eligible"
      aria-label="buy term eligible"
    >
      <AcceptTermToggle
        termsAgreed={termsAgreed}
        onToggleCallback={setTermsAgreed}
        label={
          <LegalDocumentsTermLabel
            translations={translations}
            links={{
              derivativeContractLink: legalDocumentUrl,
              prospectusLink: legalDocumentUrl,
              keyInformationDocumentLink: legalDocumentUrl,
              investorInformationDocumentLink: legalDocumentUrl,
              costTransparencyDocumentsLink: legalDocumentUrl,
            }}
          />
        }
      />
    </div>
  );
};

export { AcceptOfferStockEtfEtcEligibleTerms };
