export enum ResetTwoFaModalStep {
  INITIAL = 'initial',
  METHOD_SELECTION = 'methodSelection',
  METHOD_AUTHENTICATOR = 'methodAuthenticator',
  METHOD_RECOVERY = 'methodRecovery',
  SCAN_QR_PAGE = 'methodScanQRcode',
  INPUT_CODE_PAGE = 'methodInputCodePage',
  RECOVERY_CODES_PAGE = 'methodRecoveryCodePage',
  CONFIRMATION = 'confirmation',
}

export type DeleteTwoFaOnDelete = () => void;

export type DeleteTwoFaOnContinue = () => void;

export type DeleteTwoFaOnClose = () => void;

export type ResetTwoFaCommonProps = {
  currentPage: ResetTwoFaModalStep;
  currentStepNumber: number;
  setPage: (page: number) => void;
  recoveryCodes: string;
  setRecoveryCodes: (page: string) => void;
  onContinue: () => void;
  onClose: () => void;
  backAction: () => void;
  onDismiss: () => void;
  dismissLoading?: boolean;
};

export type ICodeResponseType = {
  secret: string;
  url: string;
} | null;
