'use client';

import { useComposableModal } from '@bts-web/utils-context';
import { FC, useEffect } from 'react';
import { useClientUserInfo } from './InitialUserInfoFetcherAndParser/utils/useClientUserInfo';
import { ResetTwoFaModal, SetupTwoFaModal } from '../components';
import { setDataDogUser } from '@bts-web/utils-next-infrastructure';

/**
 * UserInfoMountCheckActions component.
 *
 * IMPORTANT !!!!
 * Keep this separate and use it in the collective Providers component, as it needs access to both the Modal context and User context so these functions cannot be called in either of those contexts.
 * This component is responsible for checking the user's two-factor authentication status
 * and displaying the appropriate modal based on the status. It also sets the DataDog user
 * ID when the user data is available.
 *
 * @component
 *
 * @example
 * return <UserInfoMountCheckActions />
 *
 * @returns {null} This component does not render any UI.
 *
 * @remarks
 * - Uses `useComposableModal` to show modals.
 * - Uses `useClientUserInfo` to fetch user information.
 * - Displays `ResetTwoFaModal` if two-factor authentication is in the process of being reset.
 * - Displays `SetupTwoFaModal` if two-factor authentication is in the process of being set up.
 * - Sets the DataDog user ID when user data is available.
 */
const UserInfoMountCheckActions: FC = () => {
  const { showModal } = useComposableModal();

  const userData = useClientUserInfo();

  const { twoFactorAuthentication } = userData;

  useEffect(() => {
    if (twoFactorAuthentication === 'RESET_IN_PROGRESS') {
      showModal(ResetTwoFaModal);
    }

    if (twoFactorAuthentication === 'SETUP_IN_PROGRESS') {
      showModal(SetupTwoFaModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData.id) {
      setDataDogUser(userData.id);
    }
  }, [userData]);

  return null;
};

export { UserInfoMountCheckActions };
