export const ASSETS_LIST_PAGE_SIZE = 20;

export const TRANSACTION_LIST_PAGE_SIZE = 10;

export const SAVINGS_PLANS_LIST_PAGE_SIZE = 6;

export const SAVINGS_PLANS_TRANSACTIONS_LIST_PAGE_SIZE = 20;

export const CRYPTO_TRANSFERS_ASSET_ITEMS_PER_PAGE = 10;

export const REPORTS_LIST_PAGE_SIZE = 20;
