import {
  AreaChart,
  type AreaChartProps,
  type RangeSelectorConfigItem,
  TimeAmounts,
} from '@bts-web/design-system/component/chart';
import { css } from '@bts-web/utils-style-engine';

type AreaChartStylesVariantProps = {
  visual?:
    | 'filledPositive'
    | 'filledNegative'
    | 'filledNeutral'
    | 'positive'
    | 'negative'
    | 'neutral';
  size?: 'small' | 'default' | 'large' | 'medium';
};

type TabBarMiniStylesVariantProps = {
  visual: 'underlineSelected' | 'chips';
  color: 'filledPositive' | 'filledNegative' | 'filledNeutral';
};

export type ThemedAreaChartProps = AreaChartStylesVariantProps &
  AreaChartProps['external'] & {
    color?: TabBarMiniStylesVariantProps['color'];
  };

const ThemedAreaChart = ({
  visual = 'neutral',
  rangeSelectorsConfig,
  size = 'default',
  color,
  ...chartProps
}: ThemedAreaChartProps) => {
  return (
    <AreaChart
      {...chartProps}
      size={size}
      data-visual={visual}
      data-color={color}
      data-locale={chartProps.locale}
      data-size={size}
      className={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative',
        borderBottomWidth: '4px',
        borderColor: 'neutrals.widget_primary',
        '& .hidden-axis': {
          display: 'none',
        },
        // ! BELOW PROP IS IMPORTANT FOR SETTING UP THE BASE ZINDEX FOR THE WHOLE CHART, SO IT DOESN'T OVERLAP WITH FIXED HEADERS
        zIndex: 1,
        // ! BELOW PROP IS IMPORTANT FOR RECALCULATING WHEN SCREEN SHRINKS AND THE PARENT ELEMENT IS RESPONSIVE
        '& .highcharts-root': {
          width: '100% !important',
        },
        '& .highcharts-annotation-label-box': {
          fillOpacity: '0.95',
        },
        '& .highcharts-xaxis-labels': { display: 'none' },
        '& [data-highcharts-chart]': {
          width: '100% !important',
        },
        '& .highcharts-annotation-toolbar': {
          display: 'none !important',
        },
        lg: {
          '& .highcharts-xaxis-labels': {
            display: 'block',
            '& span': {
              color: 'neutrals.text_secondary',
              fontSize: 'caption.small',
              fontWeight: 'caption.small',
              letterSpacing: 'caption.small',
              lineHeight: 'caption.small',
            },
          },
          '& .highcharts-label-first': {
            position: 'absolute',
            left: '10px !important',
          },
          '& .highcharts-label-last': {
            position: 'absolute',
            right: '10px !important',
          },
        },
        '& .highcharts-background': {
          fill: 'transparent',
          stroke: 'transparent',
        },
        '& .highcharts-plot-background': {
          fill: 'transparent',
        },
        '& .highcharts-plot-border': {
          fill: 'transparent',
        },
        '& .highcharts-series-1': {
          // median
          strokeDasharray: '3, 3',
        },
        '& .highcharts-color-1': {
          // median
          stroke: '#A9A9A9',
          fill: 'transparent',
        },
        '& .highcharts-label-box': {
          fill: 'screen_background.primary',
        },
        '& .highcharts-label text': {
          fontSize: 'caption.small',
          fill: 'neutrals.text_secondary',
        },
        '& .highcharts-area': {
          strokeWidth: 0,
          fillOpacity: 1,
        },
        '& .highcharts-graph': {
          fill: 'transparent',
        },
        '& .highcharts-series.highcharts-series-1.highcharts-line-series .highcharts-graph':
          {
            strokeWidth: '1px',
          },
        '& .highcharts-series-0 .highcharts-graph': {
          strokeWidth: '2px',
        },
        '& .highcharts-visually-hidden': {
          position: 'absolute !important',
          left: '-999em',
        },
        '& .highcharts-point': { display: 'none' },
        '& [data-element="loading"]': {
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          background: 'rgba(255,255,255,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        },
        '&[data-element-type="with-tab-bar-mini"]': {
          alignItems: { lg: 'flex-end' },
          lg: {
            flexDirection: 'column-reverse',
          },
        },
        ...(visual === 'filledPositive'
          ? {
              '& .highcharts-color-0': {
                stroke: 'positive.fill_primary',
              },
              '& .highcharts-series-0 .highcharts-area': {
                fill: 'positive.fill_focused',
              },
            }
          : {}),
        ...(visual === 'filledNegative'
          ? {
              '& .highcharts-color-0': {
                stroke: 'negative.fill_primary',
              },
              '& .highcharts-series-0 .highcharts-area': {
                fill: 'negative.fill_focused',
              },
            }
          : {}),
        ...(visual === 'filledNeutral'
          ? {
              '& .highcharts-color-0': {
                stroke: 'neutrals.fill_primary',
              },
              '& .highcharts-series-0 .highcharts-area': {
                fill: 'neutrals.fill_focused',
              },
            }
          : {}),
        ...(visual === 'positive'
          ? {
              '& .highcharts-color-0': {
                stroke: 'positive.fill_primary',
              },
              '& .highcharts-series-0 .highcharts-area': {
                fill: 'screen_background.primary',
              },
              '& .highcharts-label-box': {
                fill: 'neutrals.fill_quinary',
              },
            }
          : {}),
        ...(visual === 'negative'
          ? {
              '& .highcharts-color-0': {
                stroke: 'negative.fill_primary',
              },
              '& .highcharts-series-0 .highcharts-area': {
                fill: 'screen_background.primary',
              },
              '& .highcharts-label-box': {
                fill: 'neutrals.fill_quinary',
              },
            }
          : {}),
        ...(visual === 'neutral'
          ? {
              '& .highcharts-color-0': {
                stroke: 'neutrals.fill_primary',
              },
              '& .highcharts-series-0 .highcharts-area': {
                fill: 'screen_background.primary',
              },
              '& .highcharts-label-box': {
                fill: 'screen_background.primary',
              },
            }
          : {}),
      })}
      rangeSelectorClassName={css({
        display: 'flex',
        px: 'medium',
        backgroundColor: 'screen_background.primary',
        justifyContent: 'space-between',
        gap: 'medium',
        fontSize: 'caption.medium_medium',
        fontWeight: 'caption.medium_medium',
        letterSpacing: 'caption.medium_medium',
        lineHeight: 'caption.medium_medium',
        '& button': {
          color: 'neutrals.text_secondary',
          position: 'relative',
          width: '41px',
          height: '41px',
          borderRadius: '8px',
          _active: {
            background: 'brand.fill_tertiary',
          },
          _disabled: {
            color: 'neutrals.text_disabled',

            _active: {
              background: 'transparent',
            },
          },
          '&[data-selected="true"]': {
            color: 'neutrals.text_primary',
            _after: {
              hideFrom: 'lg',
              content: '""',
              position: 'absolute',
              bottom: '5px',
              left: '0',
              right: '0',
              margin: 'auto',
              backgroundColor: 'neutrals.text_primary',
              height: '4px',
              width: '23px',
              borderRadius: '20px',
            },
          },
        },
        lg: {
          background: 'none',
          justifyContent: 'flex-start',
          gap: 'extra_small_2',
          fontSize: 'body.medium',
          fontWeight: 'body.medium',
          letterSpacing: 'body.medium',
          lineHeight: 'body.medium',
          '& button': {
            fontSize: 'body.medium',
            height: '34px',
            px: 'small',
            width: 'initial',
            backgroundColor: 'neutrals.card_fill_primary',
            borderWidth: '1px',
            borderColor: 'neutrals.stroke_secondary',
            borderRadius: '50px',
            color: 'neutrals.text_primary',
            cursor: 'pointer',
            justifyContent: 'center',
            _active: {
              background: 'brand.fill_tertiary',
            },
            _disabled: {
              backgroundColor: 'neutrals.fill_disabled',
              borderColor: 'neutrals.stroke_secondary_disabled',
              color: 'neutrals.text_disabled',
              pointerEvents: 'none',

              _active: {
                background: 'transparent',
              },
            },
            _hover: {
              backgroundColor: 'neutrals.fill_quinary',
              border: 'neutrals.stroke_primary',
            },
            "&[data-selected='true'], &:hover[data-selected='true']": {
              borderColor: 'neutrals.stroke_primary',
              backgroundColor: 'neutrals.fill_primary',
              color: 'neutrals.text_primary_inverted',
            },
          },
        },
        ...(visual === 'filledPositive'
          ? { backgroundColor: 'positive.fill_focused' }
          : {}),
        ...(visual === 'filledNegative'
          ? { backgroundColor: 'negative.fill_focused' }
          : {}),
        ...(visual === 'filledNeutral'
          ? { backgroundColor: 'neutrals.fill_focused' }
          : {}),
      })}
      timelineClassName={css({
        borderTopWidth: '1px',
        borderColor: 'neutrals.divider',
        fontSize: 'caption.medium_medium',
        fontWeight: 'caption.medium_medium',
        letterSpacing: 'caption.medium_medium',
        lineHeight: 'caption.medium_medium',
        display: 'flex',
        justifyContent: 'space-between',
        py: 'extra_small',
        gap: 'medium',
        marginLeft: 'medium',
        marginRight: 'medium',
        marginTop: 'small',
        backgroundColor: 'screen_background.primary',
        ...(visual === 'filledPositive'
          ? { backgroundColor: 'positive.fill_focused' }
          : {}),
        ...(visual === 'filledNegative'
          ? { backgroundColor: 'negative.fill_focused' }
          : {}),
        ...(visual === 'filledNeutral'
          ? { backgroundColor: 'neutrals.fill_focused' }
          : {}),
      })}
      rangeSelectorsConfig={rangeSelectorsConfig}
    />
  );
};

export { ThemedAreaChart as AreaChart, RangeSelectorConfigItem, TimeAmounts };
