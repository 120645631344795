import { Icon } from '@bts-web/design-system/component/icon';
import { TradeOptionButton } from './TradeOptionButton';
import { DrawerContent, Tag } from '../../../common';
import { getAppConfig } from '@bts-web/core-app-config';

interface TradeOptionsDrawerContentProps {
  labels: {
    dashboardDrawerTopBar: string;
    dashboardDrawerSell: string;
    dashboardDrawerConvertYourAssetsToCash: string;
    dashboardDrawerStartSavingWithARecurringBuy: string;
    dashboardDrawerSetASavingsPlan: string;
    dashboardDrawerBuyImmediately: string;
    dashboardDrawerMarketOrder: string;
    dashboardDrawerNew: string;
  };
  isSellButtonVisible: boolean;
  onClickBuyOption: () => void;
  onClickSellOption: () => void;
  onClickSavingsOption: () => void;
}

const TradeOptionsDrawerContent = ({
  labels,
  isSellButtonVisible,
  onClickBuyOption,
  onClickSellOption,
  onClickSavingsOption,
}: TradeOptionsDrawerContentProps) => {
  const { feature_hasSavingsPlansEnabled } = getAppConfig();

  return (
    <DrawerContent
      title={labels.dashboardDrawerTopBar}
      ariaLabel="trade options drawer content"
    >
      <TradeOptionButton
        data-testid="trade-options-drawer-buy-btn"
        title={labels.dashboardDrawerBuyImmediately}
        subTitle={labels.dashboardDrawerMarketOrder}
        icon={<Icon icon="marker-order" size="32" />}
        onClick={onClickBuyOption}
      />

      {feature_hasSavingsPlansEnabled && (
        <TradeOptionButton
          data-testid="trade-options-drawer-savings-btn"
          title={labels.dashboardDrawerSetASavingsPlan}
          subTitle={labels.dashboardDrawerStartSavingWithARecurringBuy}
          icon={<Icon icon="savings" size="32" />}
          onClick={onClickSavingsOption}
          tag={
            <Tag visual="filledInfoStatus" size="medium">
              {labels.dashboardDrawerNew}
            </Tag>
          }
        />
      )}

      {isSellButtonVisible && (
        <TradeOptionButton
          data-testid="trade-options-drawer-sell-btn"
          title={labels.dashboardDrawerSell}
          subTitle={labels.dashboardDrawerConvertYourAssetsToCash}
          icon={<Icon icon="sell" size="32" />}
          onClick={onClickSellOption}
        />
      )}
    </DrawerContent>
  );
};

export { TradeOptionsDrawerContent };
