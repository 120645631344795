//contains 6 AssetsPreviewSkeletons.tsx
import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../../common/components';
import { ListElementSkeleton } from './ListElementSkeleton';
import { HiddenMobile } from '../../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenMobile/HiddenMobile';

export const PortfolioOverviewSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 'medium',
      gap: 'medium',
      borderBottomWidth: '1px',
      borderBottomColor: 'neutrals.stroke_secondary',
    })}
    data-testid="portfolio-overview-skeleton"
  >
    <div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'small',
        })}
      >
        <SkeletonElement
          className={css({ margin: 'medium' })}
          width="250px"
          height="30px"
        />
        <HiddenMobile>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between',
              paddingX: 'medium',
              paddingY: 'extra_small',
            })}
          >
            <SkeletonElement width="50px" height="17px" />
            <SkeletonElement width="50px" height="17px" />
          </div>
        </HiddenMobile>
      </div>
      <div>
        <ListElementSkeleton />
        <ListElementSkeleton />
        <ListElementSkeleton />
        <ListElementSkeleton />
      </div>
    </div>
  </div>
);
