'use client';

import { useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { Icon } from '@bts-web/design-system/component/icon';
import { ButtonBase } from '../../common/components/ButtonBase/ButtonBase';

type NavigationBackButtonProps = {
  handleVirtualNavigation?: () => void;
  isDisabled?: boolean;
  noPadding?: boolean;
  icon?: string;
};

const NavigationBackButton = ({
  handleVirtualNavigation,
  isDisabled,
  noPadding,
  icon,
}: NavigationBackButtonProps) => {
  const router = useRouter();

  const navigate = useCallback(() => {
    if (handleVirtualNavigation) {
      handleVirtualNavigation();
    } else {
      router.back();

      router.refresh();
    }
  }, [handleVirtualNavigation, router]);

  return (
    <ButtonBase
      onClick={navigate}
      disabled={isDisabled}
      noPadding={noPadding}
      visual="ghost"
      size="small"
      aria-label="navigation back button"
    >
      <Icon size="24" icon={icon ? icon : 'arrow-left'} />
    </ButtonBase>
  );
};

export { NavigationBackButton };
