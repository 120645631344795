'use client';

import { ReactElement } from 'react';
import { AcceptsDataTestid } from '@bts-web/utils-test/types';
import { css } from '@bts-web/utils-style-engine';
import { CheckboxToggle } from '../CheckboxToggle/CheckboxToggle';

interface AcceptTermToggleProps extends AcceptsDataTestid {
  label: string | ReactElement;
  detailedDescription?: string;
  termsAgreed: boolean;
  onToggleCallback: () => void;
}

const AcceptTermToggle = ({
  label,
  detailedDescription,
  termsAgreed,
  onToggleCallback,
  'data-testid': testIdSuffix,
}: AcceptTermToggleProps) => (
  <>
    <div
      className={css({
        display: 'flex',
        gap: 'small',
        alignItems: 'center',
        justifyContent: 'space-between',
      })}
      data-testid={
        testIdSuffix
          ? `accept-term-toggle-${testIdSuffix}`
          : 'accept-term-toggle'
      }
    >
      <p
        className={css({
          color: 'neutrals.text_primary',
          textAlign: 'left',
          fontSize: 'body.medium',
          fontWeight: 'body.medium',
          letterSpacing: 'body.medium',
          lineHeight: 'body.medium',
        })}
      >
        {label}
      </p>

      <CheckboxToggle checked={termsAgreed} onChange={onToggleCallback} />
    </div>

    {detailedDescription ? (
      <p
        className={css({
          color: 'neutrals.text_secondary',
          textAlign: 'left',
          fontSize: 'body.medium',
          fontWeight: 'body.medium',
          letterSpacing: 'body.medium',
          lineHeight: 'body.medium',
          pt: 'small',
        })}
      >
        {detailedDescription}
      </p>
    ) : null}
  </>
);

export { AcceptTermToggle };
