import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../../common/components';

export const DesktopTopHeaderBarSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '71px',
      marginBottom: 'extra_small_2',
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 2,
      bgColor: 'neutrals.card_fill_primary',
    })}
    data-testid="header-skeleton"
  >
    <div
      className={css({
        borderRight: '1px solid',
        borderColor: 'neutrals.stroke_secondary',
        padding: '20px',
        maxWidth: '72px',
      })}
    >
      <SkeletonElement width="32px" height="32px" />
    </div>

    <div
      className={css({
        display: 'flex',
        height: '71px',
        alignItems: 'center',
        flexGrow: '1',
        paddingY: 'extra_small_2',
        paddingX: 'medium',
        gap: 'extra_small_2',
        borderBottom: '1px solid',
        borderColor: 'neutrals.stroke_secondary',
      })}
    >
      <SkeletonElement width="64px" height="38px" />
      <div
        className={css({
          marginLeft: 'auto',
          marginRight: 'medium',
          borderRight: '1px solid',
          borderColor: 'neutrals.stroke_secondary',
          gap: '8px',
          paddingX: 'medium',
          display: 'flex',
        })}
      >
        <SkeletonElement width="110px" height="38px" />
        <SkeletonElement width="79px" height="38px" />
      </div>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: 'extra_small_2',
        })}
      >
        <SkeletonElement width="102px" height="38px" />
        <SkeletonElement width="38px" height="38px" borderRadius="50%" />
      </div>
    </div>
  </div>
);
