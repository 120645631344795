import { SetStateAction, Dispatch } from 'react';
import { useRouter } from 'next/navigation';
import { TradeOfferOperation, AmountFor } from '@bts-web/data-layer/server';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import {
  isDevelopmentEnv,
  setWindowHistoryState,
} from '@bts-web/utils-functions';
import { TGQLErrorResponseExtensionCode } from '@bts-web/utils-relay';
import { IUseTradeForm } from '../useTradeForm';
import { applyAppropriatenessCheck } from '../../../../utils/applyAppropriatenessCheck';
import { TradeInputErrorType } from '../../useTradeInput';
import { initiateTrade } from '../../../../../../common/gqlActions/initiateTrade.action';
import { setInputValueToLocalStorage } from '../../../../utils/localStorageInputValueUtils';
import {
  datadogErrorHelper,
  valueFromAssetToFiat,
} from '../../../../../../common';

export const checkAppropriatenessAndCreateTradeOffer = async (
  formData: FormData,
  data: {
    currentTradeDetails: IUseTradeForm['currentTradeDetails'];
    assetDetails: IUseTradeForm['assetDetails'];
    transactionType: TradeOfferOperation;
  },
  handlers: {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setErrors: Dispatch<SetStateAction<TradeInputErrorType>>;
    processErrors: (
      gqlResponseErrors?: TGQLErrorResponseExtensionCode[],
    ) => Promise<void>;
    router: ReturnType<typeof useRouter>;
  },
) => {
  let formattedAmount = (formData.get('amount') as string).replace(/,/g, '.');

  let amountFor: AmountFor = formData.get('amountFor') as AmountFor;

  // Handle local storage
  if (amountFor === 'FIAT') {
    setInputValueToLocalStorage({
      value: formattedAmount,
      assetId: data.assetDetails.assetId,
    });
  } else {
    const fiatValue =
      valueFromAssetToFiat(formattedAmount, data.assetDetails.price)?.value ??
      '';

    setInputValueToLocalStorage({
      value: fiatValue,
      assetId: data.assetDetails.assetId,
    });
  }

  // Apply appropriateness check
  if (data.transactionType === 'BUY') {
    await applyAppropriatenessCheck({
      asset: {
        assetType: data.assetDetails.assetType,
        assetId: data.assetDetails.assetId,
      },
      transactionType: 'BUY',
    });
  }

  // Handle sell maximum exception
  if (
    data.transactionType === 'SELL' &&
    amountFor === 'FIAT' &&
    Number(formattedAmount) ===
      Number(data.currentTradeDetails.maximumTradeAmount.FIAT.value)
  ) {
    amountFor = 'ASSET';

    formattedAmount = data.currentTradeDetails.maximumTradeAmount.ASSET.value;
  }

  // Handle sell minimum exception
  if (
    data.transactionType === 'SELL' &&
    amountFor === 'ASSET' &&
    data.currentTradeDetails.minimumTradeAmount.ASSET?.value &&
    data.currentTradeDetails.minimumTradeAmount.FIAT?.value &&
    Number(formattedAmount) ===
      Number(data.currentTradeDetails.minimumTradeAmount.ASSET.value)
  ) {
    amountFor = 'FIAT';

    formattedAmount = data.currentTradeDetails.minimumTradeAmount.FIAT.value;
  }

  try {
    const resp = await initiateTrade({
      amount: formattedAmount,
      amountFor,
      assetId: data.assetDetails.assetId,
      operation: data.transactionType,
    });

    if (resp.errors) {
      handlers.processErrors(resp.errors);

      handlers.setIsLoading(false);

      return;
    }

    const { createTradeOffer } = resp?.data ?? {};

    const { id: offerId } = createTradeOffer as unknown as { id: string };

    const deepLink = createTradeOffer?.deepLink;

    if (!createTradeOffer) {
      console.error('Missing offer data');

      handlers.router.push(`/error/generic`);

      return;
    }

    if (data.transactionType === 'BUY') {
      if (deepLink) {
        if (typeof window !== 'undefined') {
          setWindowHistoryState({
            newHistoryState: {
              key: 'verifyLink',
              value: deepLink,
            },
            searchParams: window.location.search,
            pathname: window.location.pathname,
          });
        }

        handlers.setIsLoading(false);
      } else if (offerId) {
        handlers.router.push(`/trade/buy/accept?tradeOfferID=${offerId}`);
      } else {
        console.error('Missing verification link and/or offer data');

        if (!isDevelopmentEnv()) {
          handlers.router.push(`/error/generic`);
        }
      }
    } else if (data.transactionType === 'SELL') {
      if (!offerId) {
        console.error('Missing offer data');
      }

      handlers.router.push(`/trade/sell/accept?tradeOfferID=${offerId}`);
    }
  } catch (err) {
    const stringedError = JSON.stringify(err);

    handlers.setIsLoading(false);

    handlers.setErrors((state) => ({ ...state, customError: stringedError }));

    datadogErrorHelper({
      errorMessage: stringedError,
      errorSeverity: DatadogErrorLevels.CRITICAL,
    });

    if (!isDevelopmentEnv()) {
      handlers.router.push(`/error/generic`);
    }
  }
};
