export type StatusProps = {
  className?: string;
  text: string;
};

const Status = ({ className, text }: StatusProps) => {
  return (
    <span data-testid="status" className={className}>
      {text}
    </span>
  );
};

export { Status };
