'use client';

import { FC, useMemo, useState } from 'react';
import { getAppConfig } from '@bts-web/core-app-config';
import { TradeControllerVariants, TradeScreenTranslations } from '../utils';
import { useClientUserInfo } from '../../common/userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo';
import { getTranslationsPerTradeType } from '../TradeParent/MainTradeController/utils/getTranslationsPerTradeType';
import { MutableNonNullableAsset } from '../TradeParent/types';
import { TabBar } from '../../common/components/TabBar/TabBar';
import { extractPrecisionFloatValueWithDecimalsForNonUiComparisons } from '@bts-web/utils-formatting';
import { MainTradeDataFlowWrapper } from '../TradeParent/MainTradeDataFlowWrapper/MainTradeDataFlowWrapper';

interface AssetPageInitiateTradeBoxProps {
  assetInitialData: MutableNonNullableAsset;
  currentLocale: string;
  currency: string;
  tradeTranslations: TradeScreenTranslations;
}

export const AssetPageInitiateTradeBox: FC<AssetPageInitiateTradeBoxProps> = ({
  assetInitialData,
  currency,
  currentLocale,
  tradeTranslations,
}) => {
  const { feature_hasSavingsPlansEnabled } = getAppConfig();

  const userData = useClientUserInfo();

  const [transactionVariant, setTransactionVariant] =
    useState<TradeControllerVariants>('buy');

  const { translatedInnerValues } = getTranslationsPerTradeType({
    assetType: assetInitialData.__typename,
    translations: tradeTranslations,
    variant: transactionVariant,
  });

  const canSell = useMemo(() => {
    const fiatBalance =
      extractPrecisionFloatValueWithDecimalsForNonUiComparisons(
        assetInitialData?.portfolio?.fiatBalance,
      );

    const minimumSellAmount =
      extractPrecisionFloatValueWithDecimalsForNonUiComparisons(
        assetInitialData?.minimumSellAmount,
      );

    const isAssetOwned = fiatBalance > 0;

    return isAssetOwned && minimumSellAmount <= fiatBalance;
  }, [
    assetInitialData.minimumSellAmount,
    assetInitialData.portfolio?.fiatBalance,
  ]);

  const tabItems = [
    {
      label: tradeTranslations.buy,
      value: 'buy',
    },
    {
      label: tradeTranslations.sell,
      value: 'sell',
      disabled: !canSell,
    },
    ...(feature_hasSavingsPlansEnabled
      ? [
          {
            label: tradeTranslations.savingsPlan,
            value: 'savings',
          },
        ]
      : []),
  ];

  return (
    <>
      <TabBar
        onValueChange={(value) =>
          setTransactionVariant(value as TradeControllerVariants)
        }
        tabItems={tabItems}
        value={transactionVariant}
      />

      <MainTradeDataFlowWrapper
        key={`trade-${transactionVariant}`} // ensures that the inner values are reset when the variant changes
        initialData={{
          userData,
          assetData: assetInitialData,
        }}
        settings={{
          hideTopBorder: true,
        }}
        staticData={{
          currency,
          currentLocale,
          translatedInnerValues,
          translations: tradeTranslations,
          variant: transactionVariant,
        }}
      />
    </>
  );
};
