'use client';

import { ReactNode, ReactElement } from 'react';
import {
  Root,
  Portal,
  Title,
  Close,
  Overlay,
  Content,
  Description,
  DialogOverlayProps,
} from '@radix-ui/react-dialog';

export interface DialogProps {
  internal: {
    overlayClassName?: string;
    contentClassName: string;
    backIcon: ReactElement;
  };
  external: {
    overlayStyle?: DialogOverlayProps['style'];
    id: string;
    title?: string;
    open: boolean;
    onOpenChange: () => void;
    closeIcon?: ReactElement | null;
    children: ReactNode;
    animate?: 'top' | 'right' | 'bottom' | 'left';
    belowHeaderElement?: ReactElement;
    backAction?: () => void;
    disableBackdropClose?: boolean;
  };
}

const Dialog = ({
  id,
  overlayStyle,
  title,
  open,
  onOpenChange,
  closeIcon,
  children,
  animate,
  overlayClassName,
  contentClassName,
  belowHeaderElement,
  backAction,
  backIcon,
  disableBackdropClose,
}: DialogProps['internal'] & DialogProps['external']) => {
  const hasBackButton = !!backAction;

  const hasCloseButton = !!closeIcon;

  const showHeader = hasBackButton || title || hasCloseButton;

  const handlePointerDownOutside = (event: Event) => {
    if (disableBackdropClose) {
      event.preventDefault();
    }
  };

  return (
    <Root open={open} onOpenChange={onOpenChange}>
      <Portal>
        <Overlay
          data-testid={`dialog-overlay`}
          className={overlayClassName}
          style={overlayStyle}
          data-element={`dialog-overlay`}
        >
          <Content
            data-testid={`dialog-content-${id}`}
            data-element={`dialog-content`}
            className={contentClassName}
            data-side={animate}
            aria-describedby="dialog-inner-content"
            onPointerDownOutside={handlePointerDownOutside}
          >
            {showHeader ? (
              <div data-element="dialog-header">
                <div data-element="dialog-header-space">
                  {hasBackButton && (
                    <button
                      data-element={`dialog-back-btn`}
                      data-testid={`dialog-back-btn`}
                      aria-label="Back"
                      onClick={backAction}
                    >
                      {backIcon}
                    </button>
                  )}
                </div>

                {title && (
                  <Title
                    data-testid={`dialog-title`}
                    data-element={`dialog-title`}
                  >
                    {title}
                  </Title>
                )}

                <div data-element="dialog-header-space">
                  {hasCloseButton && (
                    <Close asChild>
                      <button
                        style={{ cursor: 'pointer' }}
                        data-element={`dialog-close-btn`}
                        data-testid={`dialog-close-btn`}
                        aria-label="Close"
                      >
                        {closeIcon}
                      </button>
                    </Close>
                  )}
                </div>
              </div>
            ) : (
              <Title
                data-testid={`dialog-title`}
                data-element={`dialog-title`}
                style={{ display: 'none' }}
              >
                undefined title
              </Title>
            )}
            {belowHeaderElement}

            <div id="dialog-inner-content" data-element="dialog-inner-content">
              <Description />
              {children}
            </div>
          </Content>
        </Overlay>
      </Portal>
    </Root>
  );
};

export { Dialog };
