import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../../common/components';

export const PortfolioSummarySkeleton = () => (
  <div
    className={css({
      display: 'flex',
      gap: {
        base: 'extra_small_2',
        lg: 'medium',
      },
      alignItems: 'flex-start',
      paddingY: 'small',
      paddingX: 'medium',
      justifyContent: {
        base: 'space-between',
        lg: 'flex-start',
      },
    })}
    data-testid="portfolio-summary-skeleton"
  >
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: {
          base: 'extra_small_2',
          lg: 'medium',
        },
      })}
    >
      <SkeletonElement width="146px" height="24px" />
      <SkeletonElement width="207px" height="48px" />
    </div>
    <div
      className={css({
        display: 'flex',
        height: '100%',
      })}
    >
      <div
        className={css({
          display: 'inline-flex',
          flexDirection: 'column-reverse',
          gap: {
            base: 'extra_small_3',
            lg: 'extra_small_2',
          },
          alignContent: 'flex-end',
        })}
      >
        <SkeletonElement width="64px" height="24px" />
        <SkeletonElement width="64px" height="24px" />
      </div>
    </div>
  </div>
);
