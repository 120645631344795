import { imageUrlParser } from '@bts-web/utils-formatting';
import { ImageSource } from './AssetImage.use-client';

function getImageSrc({
  src,
  width,
  height,
}: {
  src: ImageSource;
  width: string | number;
  height: string | number;
}): string {
  if (typeof src === 'string') {
    return imageUrlParser(src, Number(width), Number(height));
  }

  if (src && typeof src === 'object') {
    if ('src' in src) {
      return src.src;
    }

    if ('default' in src) {
      return typeof src.default === 'string' ? src.default : src.default.src;
    }
  }

  return '';
}

export { getImageSrc };
