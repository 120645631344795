'use client';

import {
  Sheet as DesignSheet,
  SheetProps,
} from '@bts-web/design-system/component/sheet';
import { css, Z_INDEX_LIBRARY } from '@bts-web/utils-style-engine';
import { FC } from 'react';

type ThemedSheetProps = { visual?: 'info' } & SheetProps['external'];

const Sheet: FC<ThemedSheetProps> = ({
  id,
  visual = 'info',
  isOpened,
  onOpenChange,
  children,
  title,
}) => {
  return (
    <DesignSheet
      contentStyle={{
        zIndex: Z_INDEX_LIBRARY.MODAL,
      }}
      contentClassName={css({
        borderTopRadius: 16,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        bg: 'neutrals.card_fill_primary',
        px: 'medium',
        paddingBottom: 'medium',
        paddingTop: 'extra_large_2',
        gap: 'small',
        position: 'fixed',
        animation: 'slideUpContent 150ms cubic-bezier(0.16, 1, 0.3, 1)',
        bottom: 0,
        left: 0,
        ...(visual === 'info'
          ? {
              '& > [data-element="sheet-title"]': {
                color: 'neutrals.text_primary',
                fontSize: 'headline.medium_medium',
                fontWeight: 'headline.medium_medium',
                lineHeight: 'headline.medium_medium',
                letterSpacing: 'headline.medium_medium',
                textAlign: 'left',
              },

              '& > [data-element="sheet-description"]': {
                display: 'flex',
                flexDirection: 'column',
                gap: 'medium',
              },
            }
          : {}),
      })}
      overlayClassName={css({
        position: 'fixed',
        zIndex: '10',
        display: 'flex',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        flex: 'auto',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      })}
      id={id}
      isOpened={isOpened}
      onOpenChange={onOpenChange}
      title={title}
    >
      {children}
    </DesignSheet>
  );
};

export { Sheet };
