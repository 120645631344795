import { createContext } from 'react';
import { AppUserFullInfo } from '../types';

interface IUserContextInterface {
  data: AppUserFullInfo;
  getUserInfo: () => void;
  refreshUserInfo: (preventPageRefresh?: boolean) => Promise<AppUserFullInfo>;
}

export const UserInfoContext = createContext<IUserContextInterface>(
  {} as IUserContextInterface,
);
