'use client';

import { Icon } from '@bts-web/design-system/component/icon';
import { useRouter } from 'next/navigation';
import { type ButtonComponentProps } from '../../common/types';
import { ButtonBase } from '../../common/components/ButtonBase/ButtonBase';

interface NavigationButtonProps {
  to: string;
  icon: string;
  visual?: ButtonComponentProps['visual'];
  size?: ButtonComponentProps['size'];
  ariaLabel?: string;
}

const NavigationButton = ({
  to,
  icon,
  size = 'small',
  visual = 'ghost',
  ariaLabel,
}: NavigationButtonProps) => {
  const router = useRouter();

  const navigate = () => router.push(to);

  return (
    <ButtonBase
      onClick={navigate}
      visual={visual}
      size={size}
      aria-label={ariaLabel || 'navigation button'}
    >
      <Icon size="24" icon={icon} />
    </ButtonBase>
  );
};

export { NavigationButton };
