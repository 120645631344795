import { useClientTranslation } from '@bts-web/utils-lokalise';
import { FC } from 'react';
import { TwoFaSectionTitle } from '../../../shared/TwoFaSectionTitle';
import { TwoFaSectionButtonsWrapper } from '../../../shared/TwoFaSectionButtonsWrapper';
import { TwoFaSectionDescription } from '../../../shared/TwoFaSectionDescription';
import { ButtonBase } from '../../../../../ButtonBase/ButtonBase';
import { DeleteTwoFaOnContinue, DeleteTwoFaOnDelete } from '../../utils/types';
import { css } from '@bts-web/utils-style-engine';

const ResetTwoFaInitialScreen: FC<{
  onDismiss: DeleteTwoFaOnDelete;
  onContinue: DeleteTwoFaOnContinue;
  dismissLoading: boolean;
}> = ({ onDismiss, onContinue, dismissLoading }) => {
  const { t } = useClientTranslation();

  return (
    <>
      <TwoFaSectionTitle title={`${t('2fa_reset_are_you_sure')}`} />
      <TwoFaSectionDescription textContent={`${t('2fa_reset_description')}`} />
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          isLoading={dismissLoading}
          onClick={onContinue}
          visual="accent"
          size="large"
          fullWidth
          disabled={dismissLoading}
        >
          {t('2fa_reset_title')}
        </ButtonBase>
        <ButtonBase
          disabled={dismissLoading}
          isLoading={dismissLoading}
          visual={'ghost'}
          onClick={onDismiss}
          className={css({
            textDecoration: 'underline',
          })}
        >
          {t('2fa_reset_skip')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { ResetTwoFaInitialScreen };
