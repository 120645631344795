/**
 * @generated SignedSource<<23a6492e88ec0823ed5a242895728962>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReportTrigger = "ON_DEMAND" | "SCHEDULED";
export type ReportType = "ACCOUNT_STATEMENT" | "CRYPTO_TAX";
export type ReportsInput = {
  after?: string | null;
  first?: number | null;
  read?: boolean | null;
  type?: ReportType | null;
};
export type ReportsViewQuery$variables = {
  input?: ReportsInput | null;
};
export type ReportsViewQuery$data = {
  readonly reports: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: any | null;
        readonly displayName: string | null;
        readonly downloadLink: string | null;
        readonly downloadLinkExpiryDate: any | null;
        readonly id: string;
        readonly read: boolean | null;
        readonly trigger: ReportTrigger | null;
        readonly type: ReportType | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    } | null;
  } | null;
};
export type ReportsViewQuery = {
  response: ReportsViewQuery$data;
  variables: ReportsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReportsConnection",
    "kind": "LinkedField",
    "name": "reports",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReportsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trigger",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "downloadLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "downloadLinkExpiryDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "read",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportsViewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportsViewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "84d048103fa85d6166f8f04a7ae4acf4",
    "id": null,
    "metadata": {},
    "name": "ReportsViewQuery",
    "operationKind": "query",
    "text": "query ReportsViewQuery(\n  $input: ReportsInput\n) {\n  reports(input: $input) {\n    edges {\n      node {\n        id\n        type\n        displayName\n        trigger\n        downloadLink\n        downloadLinkExpiryDate\n        read\n        createdAt\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee7e94c82ca0730325222de108aed6b5";

export default node;
