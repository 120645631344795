'use client';

import { FC } from 'react';
import { TwoFaSectionTitle } from '../../shared/TwoFaSectionTitle';
import { css } from '@bts-web/utils-style-engine';
import { ButtonBase } from '../../../../ButtonBase/ButtonBase';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TwoFaSectionDescription } from '../../shared/TwoFaSectionDescription';
import { TwoFaSectionButtonsWrapper } from '../../shared/TwoFaSectionButtonsWrapper';
import { TTwoFaCommonProps } from '../types';

const InitialPage: FC<
  Pick<TTwoFaCommonProps, 'onDismiss' | 'onContinue' | 'dismissLoading'>
> = ({ onContinue, onDismiss, dismissLoading }) => {
  const { t } = useClientTranslation();

  return (
    <>
      <TwoFaSectionTitle title={`${t('2fa_welcome_subtitle')}`} />
      <TwoFaSectionDescription textContent={t('2fa_welcome_body')} />
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          isLoading={dismissLoading}
          onClick={onContinue}
          visual="accent"
          size="large"
          fullWidth
          disabled={dismissLoading}
        >
          {t('continue')}
        </ButtonBase>
        <ButtonBase
          disabled={dismissLoading}
          isLoading={dismissLoading}
          visual={'ghost'}
          onClick={onDismiss}
          className={css({
            textDecoration: 'underline',
          })}
        >
          {t('2fa_welcome_cta_skip')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { InitialPage };
