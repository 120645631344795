import { ReactNode } from 'react';
import { Breadcrumbs } from '@bts-web/design-system/component/breadcrumbs';

export interface TabBreadcrumbsProps {
  internal: {
    className: string;
    breadCrumbsClassName: string;
  };
  external: {
    children: ReactNode;
    LeftComponent?: ReactNode;
  };
}

export function TabBreadcrumbs({
  className,
  breadCrumbsClassName,
  children,
  LeftComponent,
}: TabBreadcrumbsProps['internal'] & TabBreadcrumbsProps['external']) {
  return (
    <div className={className} data-testid="tab-breadcrumbs">
      {LeftComponent}
      <Breadcrumbs className={breadCrumbsClassName}>{children}</Breadcrumbs>
    </div>
  );
}

export default TabBreadcrumbs;
