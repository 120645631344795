import { redirect } from 'next/navigation';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import { MutableNonNullableUser } from '../types';
import {
  AssetType,
  TradeControllerVariants,
  TradeScreenTranslations,
} from '../../utils';
import { applyAppropriatenessCheck } from '../utils/applyAppropriatenessCheck';
import { getTranslationsPerTradeType } from './utils/getTranslationsPerTradeType';
import { getServerUserInfo } from '../../../common/userinfo/InitialUserInfoFetcherAndParser/utils/getServerUserInfo';
import { getAssetById } from '../../../common/gqlActions';
import {
  NavigationBackButton,
  NavigationButton,
} from '../../../navigation/index';
import { NavigationBar } from '../../../common';
import { MainTradeDataFlowWrapper } from '../MainTradeDataFlowWrapper/MainTradeDataFlowWrapper';

export interface TradeControllerPropsBase {
  assetId: string;
  currentLocale: string;
  variant: TradeControllerVariants;
  currency: string;
  translations: TradeScreenTranslations;
  // is used to disable the appropriateness check when this component is used purely for background blurry purposes
  skipCheckAppropriateness?: boolean;
}

const TradeController = async ({
  variant,
  assetId,
  currentLocale,
  currency,
  translations,
  skipCheckAppropriateness,
}: TradeControllerPropsBase) => {
  const assetByIdResponse = await getAssetById(assetId);

  const { assetById } = assetByIdResponse.data ?? {};

  const userData = await getServerUserInfo();

  if (!assetById) {
    if (!isDevelopmentEnv()) {
      redirect('/error/generic');
    }

    return null;
  }

  if (['buy', 'savings'].includes(variant) && !skipCheckAppropriateness) {
    await applyAppropriatenessCheck({
      asset: {
        assetId: assetId,
        assetType: assetById.__typename as AssetType,
      },
      transactionType: variant.toUpperCase() as 'BUY' | 'SAVINGS',
    });
  }

  const { translatedInnerValues } = getTranslationsPerTradeType({
    assetType: assetById?.__typename,
    translations,
    variant,
  });

  const variantsTitles: Record<TradeControllerVariants, string> = {
    buy: translations.buy,
    sell: translations.sell,
    savings: translations.savingsPlan,
  };

  return (
    <>
      <NavigationBar
        title={variantsTitles[variant]}
        NavigationLeftSlot={<NavigationBackButton />}
        NavigationRightSlot={
          <NavigationButton
            icon="dismiss"
            to="/assets/portfolio"
            ariaLabel="close trade"
          />
        }
      />

      <MainTradeDataFlowWrapper
        initialData={{
          assetData: assetById,
          userData: userData as MutableNonNullableUser,
        }}
        staticData={{
          currentLocale,
          translations,
          variant,
          currency,
          translatedInnerValues: translatedInnerValues,
        }}
      />
    </>
  );
};

export { TradeController };
