'use client';

import {
  TextInput,
  TextInputProps,
} from '@bts-web/design-system/component/text-input';
import { cx, vstack } from '@bts-web/utils-style-engine';

type ThemedTextInputProps = TextInputProps['external'];

const ThemedTextInput = (props: ThemedTextInputProps) => (
  <TextInput
    className={cx(
      vstack({
        alignItems: 'normal',
        gap: 'extra_small_2',
        marginLeft: 'medium',
        marginRight: 'medium',
        '& [data-element="input"]': {
          borderWidth: '1px',
          borderStyle: 'solid',
          backgroundColor: 'transparent',
          outline: 'none',
          borderRadius: '4px',
          borderColor: 'neutrals.fill_tertiary',
          color: 'neutrals.text_primary',
          '&:not(:valid)': {
            color: 'neutrals.text_secondary',
          },
          padding: 'small',
          height: '56px',
          zIndex: '2',
          fontSize: 'title.medium',
          fontWeight: 'title.medium',
          lineHeight: 'title.medium',
          letterSpacing: 'title.medium',
        },
        '& [data-element="label"]': {
          color: 'neutrals.text_primary',
          fontSize: 'label.medium_medium',
          fontWeight: 'label.medium_medium',
          lineHeight: 'label.medium_medium',
          letterSpacing: 'label.medium_medium',
        },
        '& [data-element="helper-text"]': {
          color: 'neutrals.text_secondary',
          fontSize: 'caption.small_light',
          fontWeight: 'caption.small_light',
          lineHeight: 'caption.small_light',
          letterSpacing: 'caption.small_light',
        },
      }),
    )}
    {...props}
  />
);

export { ThemedTextInput as TextInput };
