import { useClientTranslation } from '@bts-web/utils-lokalise';
import { FC, useState } from 'react';
import { TwoFaSectionTitle } from '../../../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../../../shared/TwoFaSectionDescription';
import { TwoFaSectionButtonsWrapper } from '../../../shared/TwoFaSectionButtonsWrapper';
import { ButtonBase } from '../../../../../ButtonBase/ButtonBase';
import { DeleteTwoFaOnContinue, ICodeResponseType } from '../../utils/types';
import { getAppConfig } from '@bts-web/core-app-config';
import { css } from '@bts-web/utils-style-engine';
import { HyperLinkButtonBase } from '../../../../../HyperLinkButtonBase/HyperLinkButtonBase';
import { DigitCodeInput } from '../../../../../DigitCodeInput';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/utils-relay';
import { submitResetTwoFaAppCode } from '../../../../../../../auth';

type TwoFaInputStatus = 'invalid' | 'valid';

const ResetTwoFaMethodAppCode: FC<{
  onContinue: DeleteTwoFaOnContinue;
  dismissLoading?: boolean;
  bindICodeResponse: (response: ICodeResponseType) => void;
}> = ({ onContinue, dismissLoading, bindICodeResponse }) => {
  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const [inputStatus, setInputStatus] = useState<TwoFaInputStatus>('valid');

  const { URLforCustomerSupport } = getAppConfig();

  const [inputCode, setInputCode] = useState('');

  const [localLoading, setLocalLoading] = useState(false);

  const isCodeValid =
    inputCode.length === 6 &&
    inputCode.split('').every((digit) => /^\d$/.test(digit));

  const { t } = useClientTranslation();

  const anyLoading = dismissLoading || localLoading;

  const onSubmitCode = async (code: string) => {
    setLocalLoading(true);

    try {
      const resp = await submitResetTwoFaAppCode(code);

      if (resp.errors || resp.message === 'invalid code') {
        setInputStatus('invalid');

        if (resp.errors) {
          processErrors(resp.errors);
        }

        setLocalLoading(false);

        return;
      } else {
        setLocalLoading(false);

        onContinue();

        bindICodeResponse(resp);
      }
    } catch (error) {
      setLocalLoading(false);

      setInputStatus('invalid');

      return;
    }
  };

  const onChangeInput = (isFilled: boolean, code: string) => {
    if (code !== inputCode) {
      setInputStatus('valid');
    }

    setInputCode(code);
  };

  return (
    <>
      <TwoFaSectionTitle title={`${t('2fa_reset_appcode_title')}`} />
      <TwoFaSectionDescription
        textContent={`${t('2fa_reset_appcode_description')}`}
      />
      <div>
        <DigitCodeInput
          onChange={onChangeInput}
          hasError={inputStatus === 'invalid'}
          className={css({
            width: '100%',
            maxWidth: '340px',
            margin: '0 auto',
          })}
        />
        {inputStatus === 'invalid' && (
          <p
            className={css({
              color: 'negative.text_primary',
              textAlign: 'center',
              py: 'extra_small_3',
              fontSize: 'caption.small',
            })}
          >
            {t('2fa_reset_invalid_code')}
          </p>
        )}
      </div>

      <TwoFaSectionButtonsWrapper>
        {URLforCustomerSupport && (
          <HyperLinkButtonBase
            visual={'ghost'}
            to={URLforCustomerSupport}
            className={css({
              textDecoration: 'underline',
            })}
          >
            {t('2fa_reset_option_helpdesk')}
          </HyperLinkButtonBase>
        )}
        <ButtonBase
          isLoading={anyLoading}
          onClick={() => onSubmitCode(inputCode)}
          visual="accent"
          size="large"
          fullWidth
          disabled={anyLoading ? true : !isCodeValid || inputStatus !== 'valid'}
        >
          {t('2fa_reset_button')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { ResetTwoFaMethodAppCode };
