import { css } from '@bts-web/utils-style-engine';
import { PortfolioSummarySkeleton } from '../../../portfolio/Content/PortfolioSummary/PortfolioSummarySkeleton';
import { PortfolioOverviewSkeleton } from '../../../portfolio/Content/PortfolioOverview/PortfolioOverviewSkeleton';
import { PortfolioAreaChartSkeleton } from '../../../portfolio/Content/PortfolioAreaChart/PortfolioAreaChartSkeleton';

const AppMainLayoutWrapperSkeleton = () => {
  return (
    <main
      className={css({
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        paddingBottom: 0,
        marginX: 'auto',
        width: {
          base: '100%',
          lg: '1440px',
        },
        maxWidth: '100%',
      })}
      data-testid="desktop-main-trading-area-skeleton"
    >
      <PortfolioSummarySkeleton />
      <PortfolioAreaChartSkeleton />
      <PortfolioOverviewSkeleton />
    </main>
  );
};

export { AppMainLayoutWrapperSkeleton };
