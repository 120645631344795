import { Root, Portal, Title, Overlay, Content } from '@radix-ui/react-dialog';
import { ReactNode } from 'react';

export interface SheetProps {
  internal: {
    overlayClassName?: string;
    contentClassName: string;
  };
  external: {
    children?: ReactNode;
    title?: string;
    contentStyle?: React.CSSProperties;
    isOpened: boolean;
    onOpenChange: () => void;
    id: string;
  };
}

const Sheet = ({
  id,
  title,
  isOpened,
  onOpenChange,
  children,
  overlayClassName,
  contentClassName,
  contentStyle,
}: SheetProps['internal'] & SheetProps['external']) => (
  <Root open={isOpened} onOpenChange={onOpenChange}>
    <Portal>
      <Overlay data-testid="sheet-overlay" className={overlayClassName} />
      <Content
        style={contentStyle}
        data-testid={`sheet-content-${id}`}
        className={contentClassName}
      >
        {title && (
          <Title data-testid="sheet-title" data-element="sheet-title">
            {title}
          </Title>
        )}
        <div data-testid="sheet-description" data-element="sheet-description">
          {children}
        </div>
      </Content>
    </Portal>
  </Root>
);

export { Sheet };
