import { css } from '@bts-web/utils-style-engine';
import { FC, PropsWithChildren } from 'react';

const TwoFaSectionButtonsWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
        width: '100%',
        margin: '0 auto',
        maxWidth: '342px',
      })}
    >
      {children}
    </div>
  );
};

export { TwoFaSectionButtonsWrapper };
