import { useCallback, useMemo, useState } from 'react';
import { ResetTwoFaModalStep } from './types';

export const useResetTwoFactorAuthState = () => {
  const [currentPage, setPage] = useState<ResetTwoFaModalStep>(
    ResetTwoFaModalStep.INITIAL,
  );

  const [recoveryCodes, setRecoveryCodes] = useState<string>('');

  const onContinue = useCallback(() => {
    switch (currentPage) {
      case ResetTwoFaModalStep.INITIAL:
        setPage(ResetTwoFaModalStep.METHOD_SELECTION);

        break;

      case ResetTwoFaModalStep.METHOD_AUTHENTICATOR:
        setPage(ResetTwoFaModalStep.SCAN_QR_PAGE);

        break;

      case ResetTwoFaModalStep.METHOD_RECOVERY:
        setPage(ResetTwoFaModalStep.SCAN_QR_PAGE);

        break;

      case ResetTwoFaModalStep.SCAN_QR_PAGE:
        setPage(ResetTwoFaModalStep.INPUT_CODE_PAGE);

        break;

      case ResetTwoFaModalStep.INPUT_CODE_PAGE:
        setPage(ResetTwoFaModalStep.RECOVERY_CODES_PAGE);

        break;

      case ResetTwoFaModalStep.RECOVERY_CODES_PAGE:
        setPage(ResetTwoFaModalStep.CONFIRMATION);

        break;

      default:
        break;
    }
  }, [currentPage]);

  const currentStepNumber = useMemo(() => {
    switch (currentPage) {
      case ResetTwoFaModalStep.INITIAL:
        return 0;

      case ResetTwoFaModalStep.METHOD_SELECTION:
        return 1;

      case ResetTwoFaModalStep.METHOD_RECOVERY:
        return 2;

      case ResetTwoFaModalStep.METHOD_AUTHENTICATOR:
        return 2;

      case ResetTwoFaModalStep.SCAN_QR_PAGE:
        return 3;

      case ResetTwoFaModalStep.INPUT_CODE_PAGE:
        return 4;

      case ResetTwoFaModalStep.RECOVERY_CODES_PAGE:
        return 5;

      case ResetTwoFaModalStep.CONFIRMATION:
        return 6;

      default:
        return 0;
    }
  }, [currentPage]);

  return {
    onContinue,
    currentPage,
    currentStepNumber,
    setPage,
    recoveryCodes,
    setRecoveryCodes,
  };
};
