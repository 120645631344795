export const getInputClassNameByAmountLength = (amount: string): string => {
  if (amount && amount.length >= 16) {
    return 'ellipsis-input-font';
  }

  if (amount.length > 14) {
    return 'small-input-font';
  }

  if (amount.length >= 8) {
    return 'medium-input-font';
  }

  return 'large-input-font';
};
