import {
  SavingPlansFrameTranslationsKeys,
  SavingsPlansListTranslationsKeys,
  SavingsPlansTransactionsTranslationsKeys,
  StartSavingTranslationsKeys,
} from '../types';

type SavingPlansTranslationsKeys = SavingPlansFrameTranslationsKeys &
  StartSavingTranslationsKeys &
  SavingsPlansListTranslationsKeys &
  SavingsPlansTransactionsTranslationsKeys;

export const initialSavingsPlansTranslations: SavingPlansTranslationsKeys = {
  savingsPlans: 'saving_plans',
  savingsPlanStartSavingButton: 'saving_plans_frame_start_saving_button',
  savingsPlanManageButton: 'saving_plans_frame_manage_button',
  noPlan: 'saving_plans_frame_no_plan',
  startSavingTitle: 'start_saving_sheet_title',
  startSavingSectionTitleOne: 'start_saving_sheet_section_title_1',
  startSavingSectionTitleTwo: 'start_saving_sheet_section_title_2',
  startSavingSectionTitleThree: 'start_saving_sheet_section_title_3',
  startSavingSectionDescriptionOne: 'start_saving_sheet_section_description_1',
  startSavingSectionDescriptionTwo: 'start_saving_sheet_section_description_2',
  startSavingSectionDescriptionThree:
    'start_saving_sheet_section_description_3',
  continue: 'continue',
  savingsPlanSuccessTitle: 'savings_plan_success_title',
  savingsPlanSuccessSubtitle: 'savings_plan_success_subtitle',
  savingsPlanSuccessBodyLine1: 'savings_plan_success_body_line_1',
  savingsPlanSuccessBodyLine2: 'savings_plan_success_body_line_2',
  savingsPlanSuccessBodyLine3: 'savings_plan_success_body_line_3',
  savingsPlanSuccessBody2Line1: 'savings_plan_success_body2_line_1',
  savingsPlanSuccessBody2Line3: 'savings_plan_success_body2_line_3',
  savingsPlanSuccessBody2Line2: 'savings_plan_success_body2_line_2',
  okGoToPortfolio: 'ok_go_to_portfolio',
  savingsPlanSuccessCheckItOut: 'savings_plan_success_check_it_out',
  savingsPlanFailedTitle: 'savings_plan_failed_title',
  savingsPlanFailedSubtitle: 'savings_plan_failed_subtitle',
  tryAgain: 'try_again',
  cancelTransaction: 'cancel_transaction',
  poweredByBitpanda: 'powered_by_bitpanda',
  savingsPlansListTitleActive: 'savings_plans_list_title_active',
  savingsPlansListTitleCancelled: 'savings_plans_list_title_cancelled',
  savingsPlansListTitleEmpty: 'savings_plans_list_title_empty',
  savingsPlansListSubtitle: 'savings_plans_list_subtitle',
  savingsPlansListSubtitleEmpty: 'savings_plans_list_subtitle_empty',
  savingsPlansListMonthly: 'savings_plans_list_monthly',
  savingsPlansListMonthlyNextOn: 'savings_plans_list_monthly_next_on',
  savingsPlansListActive: 'savings_plans_list_active',
  savingsPlansListCancelled: 'savings_plans_list_cancelled',
  savingsPlansListEmpty: 'savings_plans_list_empty',
  savingsPlansListCreateButton: 'savings_plans_list_create_button',
  viewAllTransactions: 'view_all_transactions',
  latestTransactions: 'dashboard_portfolio_latest_transactions',
  emptyStateNoTransactions: 'empty_state_no_transactions',
  transactions: 'transactions',
  transactionsListEmpty: 'savings_plans_list_empty',
};

const getSavingsPlansTranslations = (
  t: (translationKey: keyof SavingPlansTranslationsKeys) => string,
): SavingPlansTranslationsKeys => {
  return Object.entries(initialSavingsPlansTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof SavingPlansTranslationsKeys] = t(
        value as keyof SavingPlansTranslationsKeys,
      );

      return acc;
    },
    {} as SavingPlansTranslationsKeys,
  );
};

export { getSavingsPlansTranslations };
