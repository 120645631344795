'use client';

import { Dispatch, SetStateAction, createContext } from 'react';

interface TradeContextValue {
  isSellButtonVisible: boolean;
  setOnClickTradeActions: (handlers: {
    buy: () => void;
    sell: () => void;
    savings: () => void;
  }) => void;
  setSellButtonDisablerKeys: Dispatch<SetStateAction<string[]>>;
}

const TradeContext = createContext<TradeContextValue>({} as TradeContextValue);

export { TradeContext };
