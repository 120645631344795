import { css, stack } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../../../../../common';

export const SetUpSavingsPlanBoxSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      gap: 'large',
      boxShadow:
        '0px 4px 15px 0px rgba(0, 0, 0, 0.10), 0px 10px 20px 0px rgba(0, 0, 0, 0.05)',
      borderRadius: '8px',
      px: 'medium',
      pt: 'small',
      pb: 'large',
      mx: 'medium',
      mt: 'medium',
    })}
  >
    <div className={css({ display: 'flex', gap: 'small' })}>
      <SkeletonElement width="46px" height="46px" borderRadius="4px" />

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_3',
        })}
      >
        <SkeletonElement width="140px" height="17px" />

        <SkeletonElement width="250px" height="40px" />
      </div>
    </div>

    <ul>
      <li
        className={stack({
          borderBottom: '1px solid',
          borderBottomColor: 'neutrals.divider',
          gap: 'extra_small_2',
          pb: 'medium',
        })}
      >
        <SkeletonElement width="70px" height="17px" />

        <SkeletonElement width="90px" height="19px" />
      </li>

      <li
        className={stack({
          gap: 'extra_small_2',
          pt: 'medium',
        })}
      >
        <SkeletonElement width="70px" height="17px" />

        <SkeletonElement width="90px" height="19px" />
      </li>
    </ul>
  </div>
);
