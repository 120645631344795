import { SkeletonElement } from '../../../common/components';
import { css } from '@bts-web/utils-style-engine';

const MobileHeaderSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingX: ' medium',
      paddingY: 'small',
    })}
    data-testid="mobile-header-skeleton"
  >
    <div
      className={css({ display: 'flex', alignItems: 'center', gap: 'medium' })}
    >
      <SkeletonElement width="21px" height="31px" />
    </div>

    <div className={css({ display: 'flex', gap: 'small' })}>
      <SkeletonElement width="20px" height="20px" />
      <SkeletonElement width="20px" height="20px" />
    </div>
  </div>
);

export { MobileHeaderSkeleton };
