'use client';

import { useContext } from 'react';
import { UserInfoContext } from '../../UserInfoContext/UserInfoContext';
import { AppUserFullInfo } from '../../types';

export const useClientUserInfo = (): AppUserFullInfo => {
  const context = useContext(UserInfoContext);

  if (!context) {
    throw new Error('useClientUserInfo must be used within a UserInfoProvider');
  }

  return context.data;
};

export const useClientUserInfoUpdater = () => {
  return useContext(UserInfoContext);
};
