'use client';

import { FC, ReactNode, useContext, useState } from 'react';
import { AppUserFullInfo } from '../types';
import { UserInfoContext } from './UserInfoContext';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { getServerUserInfo } from '../InitialUserInfoFetcherAndParser/utils/getServerUserInfo';
import { useRouter } from 'next/navigation';
import { datadogErrorHelper } from '../../utils/datadogErrorHelper';

/**
 * UserInfoProvider component provides user information context to its children.
 * It manages user data state and handles modal display for two-factor authentication setup or disable processes.
 *
 * @component
 * @param {Object} props - Component props
 * @param {AppUserFullInfo} props.data - Initial user data
 * @param {ReactNode} props.children - Child components that will have access to the user information context
 *
 * @example
 * <UserInfoProvider data={userData}>
 *   <YourComponent />
 * </UserInfoProvider>
 *
 * @returns {JSX.Element} The UserInfoContext provider with user data and related functions
 */
export const UserInfoProvider: FC<{
  data: AppUserFullInfo;
  children: ReactNode;
}> = ({ children, data }) => {
  const { refresh: routerRefresh } = useRouter();

  const [userData, setUserData] = useState<AppUserFullInfo>(data);

  const refreshUserInfo = async (preventPageRefresh?: boolean) => {
    try {
      const newUserData = await getServerUserInfo();

      setUserData(newUserData);

      // for cases when we don't want a page refresh after user data sync
      // (e.g. after user corporate-onboarding success, the routerRefresh is causing an extra redirection to /assets/portfolio)
      if (!preventPageRefresh) {
        routerRefresh();
      }

      return newUserData;
    } catch (error) {
      datadogErrorHelper({
        errorMessage: JSON.stringify(error),
        errorSeverity: DatadogErrorLevels.HIGH,
      });

      return {} as AppUserFullInfo;
    }
  };

  return (
    <UserInfoContext.Provider
      value={{
        data: userData,
        getUserInfo: () => {
          return userData;
        },
        refreshUserInfo: refreshUserInfo,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};

/**
 * Custom hook to access the UserInfoContext.
 *
 * This hook provides a convenient way to access the user information context
 * within the application. It uses the `useContext` hook to retrieve the context
 * value from `UserInfoContext`.
 *
 * @returns {UserInfoContextType} The current context value for user information.
 */
export const useUserInfoContext = () => {
  const context = useContext(UserInfoContext);

  return context;
};
