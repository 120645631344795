/**
 * @generated SignedSource<<bbafcc9f9b593831d99373e186a58268>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InternalSavingsPlanTransactionState = "CONFIRMED" | "FAILED" | "FINISHED" | "SCHEDULED";
export type InternalSavingsPlanTransactionsInput = {
  after?: string | null;
  first?: number | null;
  savingsPlanId?: string | null;
  state?: InternalSavingsPlanTransactionState | null;
};
export type SavingsPlansTransactionsQuery$variables = {
  input?: InternalSavingsPlanTransactionsInput | null;
};
export type SavingsPlansTransactionsQuery$data = {
  readonly savingsPlanTransactions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly asset: {
          readonly id: string;
          readonly logoUrl: string | null;
          readonly name: string | null;
          readonly symbol: string | null;
        } | null;
        readonly assetAmount: PrecisionFloat | null;
        readonly createdAt: any | null;
        readonly externalTransaction: {
          readonly id: string;
        } | null;
        readonly fiatAmount: PrecisionFloat | null;
        readonly id: string;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    } | null;
  } | null;
};
export type SavingsPlansTransactionsQuery = {
  response: SavingsPlansTransactionsQuery$data;
  variables: SavingsPlansTransactionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fiatAmount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetAmount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SavingsPlansTransactionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InternalSavingsPlanTransactionsConnection",
        "kind": "LinkedField",
        "name": "savingsPlanTransactions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InternalSavingsPlanTransactionsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InternalSavingsPlanTransaction",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "externalTransaction",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SavingsPlansTransactionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InternalSavingsPlanTransactionsConnection",
        "kind": "LinkedField",
        "name": "savingsPlanTransactions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InternalSavingsPlanTransactionsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InternalSavingsPlanTransaction",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "externalTransaction",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v3/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "482b4f7135400fb4c2c16d4d4c61080b",
    "id": null,
    "metadata": {},
    "name": "SavingsPlansTransactionsQuery",
    "operationKind": "query",
    "text": "query SavingsPlansTransactionsQuery(\n  $input: InternalSavingsPlanTransactionsInput\n) {\n  savingsPlanTransactions(input: $input) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        fiatAmount\n        assetAmount\n        createdAt\n        externalTransaction {\n          __typename\n          id\n        }\n        asset {\n          __typename\n          id\n          name\n          symbol\n          logoUrl\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ec5ac597d7dffbcd24bb0f9120cf6cf";

export default node;
