'use client';

import { Fragment } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { languageTitleByCode, type Language } from '@bts-web/utils-lokalise';
import { lokaliseLanguages } from '@bts-web/utils-lokalise';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { LanguageChangeMenuItem } from '../LanguageChangeMenuItem/LanguageChangeMenuItem';
import { TopBarLanguageTranslations } from '../../layouts/LayoutWithLeftDesktopNav/DesktopTopHeaderBar/subcomponents/TopbarLanguageMenuWrapper/TopbarLanguageMenuWrapper';

export const LanguageChangeMenu = ({
  translations,
  onLanguageValueChange,
  currentValue,
  isWithinDropdown,
}: {
  translations: TopBarLanguageTranslations;
  currentValue: string;
  onLanguageValueChange: (languageValue: string) => void;
  isWithinDropdown?: boolean;
}) => {
  const labelsByLanguage: Record<Language, string> = {
    de: translations.germanSubtitle,
    en: translations.englishSubtitle,
  };

  const WrapperElement = isWithinDropdown ? DropdownMenuItem : Fragment;

  return (
    <ul
      className={css({
        border: '1px solid',
        borderColor: 'neutrals.stroke_secondary',
        backgroundColor: 'neutrals.stroke_secondary',
        maxHeight: '285px',
        overflow: 'auto',
        display: 'flex',
        borderRadius: '12px',
        flexDirection: 'column',
        gap: '1px',
        '& li': {
          width: '100%',
          '&:first-of-type': {
            borderTopLeftRadius: '13px',
            borderTopRightRadius: '13px',
          },
          '&:last-of-type': {
            borderBottomLeftRadius: '13px',
            borderBottomRightRadius: '13px',
          },
        },
      })}
    >
      {lokaliseLanguages.languages.map((language) => (
        <li key={language.lang_iso}>
          {/* this DropdownMenuItem needs to be here in order for the menu to close on click */}
          <WrapperElement>
            <LanguageChangeMenuItem
              onChange={() =>
                onLanguageValueChange(language.lang_iso as Language)
              }
              subtitle={labelsByLanguage[language.lang_iso as Language]}
              title={languageTitleByCode[language.lang_iso as Language]}
              active={currentValue === language.lang_iso}
              disabled={currentValue === language.lang_iso}
            />
          </WrapperElement>
        </li>
      ))}
    </ul>
  );
};
