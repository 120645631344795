import { DesktopTopHeaderBarSkeleton } from '../../../../layouts/LayoutWithLeftDesktopNav/DesktopTopHeaderBar/DesktopTopHeaderBarSkeleton';
import { DesktopLeftNavSkeleton } from '../../../../layouts/LayoutWithLeftDesktopNav/DesktopLeftNav/DesktopLeftNavSkeleton';
import { css } from '@bts-web/utils-style-engine';
import { AppMainLayoutWrapperSkeleton } from '../../../../layouts/LayoutWithLeftDesktopNav/AppMainLayoutWrapper/AppMainLayoutWrapperSkeleton';
import { HiddenMobile } from '../../../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenMobile/HiddenMobile';
import { HiddenDesktop } from '../../../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';
import { MobileNavWithTradeSkeleton } from '../../../../layouts/LayoutWithLeftDesktopNav/MobileNavWithTrade/MobileNavWithTradeSkeleton';
import { MobileHeaderSkeleton } from '../../../../layouts/LayoutWithLeftDesktopNav/MobileHeader/MobileHeaderSkeleton';

const LoadingSkeletonScreen = () => (
  <div
    className={css({
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: { base: undefined, lg: 'neutrals.card_fill_primary' },
      paddingLeft: {
        base: undefined,
        lg: '75px',
      },
      paddingTop: {
        base: undefined,
        lg: '75px',
      },
      height: '100vh',
      overflow: 'hidden',
    })}
    data-testid="loading-skeleton-screen"
  >
    <HiddenMobile>
      <DesktopTopHeaderBarSkeleton />
    </HiddenMobile>

    <HiddenMobile>
      <DesktopLeftNavSkeleton />
    </HiddenMobile>

    <HiddenDesktop>
      <MobileNavWithTradeSkeleton />
      <MobileHeaderSkeleton />
    </HiddenDesktop>

    <AppMainLayoutWrapperSkeleton />
  </div>
);

export { LoadingSkeletonScreen };
