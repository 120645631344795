/**
 * @generated SignedSource<<6575c1e9b8960b84050daef30c4173f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SavingsPlanFrequency = "BIWEEKLY" | "MONTHLY" | "WEEKLY";
export type CreateSavingsPlanInput = {
  amount: string;
  assetId: string;
  date: any;
  frequency?: SavingsPlanFrequency | null;
};
export type CreateSavingsPlanMutation$variables = {
  input?: CreateSavingsPlanInput | null;
};
export type CreateSavingsPlanMutation$data = {
  readonly createSavingsPlan: {
    readonly __typename: "SavingsPlan";
    readonly id: string;
  } | {
    readonly __typename: "SavingsPlanSignatureNeeded";
    readonly deepLink: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type CreateSavingsPlanMutation = {
  response: CreateSavingsPlanMutation$data;
  variables: CreateSavingsPlanMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deepLink",
      "storageKey": null
    }
  ],
  "type": "SavingsPlanSignatureNeeded",
  "abstractKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "SavingsPlan",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSavingsPlanMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createSavingsPlan",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSavingsPlanMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createSavingsPlan",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v4/*: any*/),
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f8ce003bbc6ebb9e5327de5c04cbc79",
    "id": null,
    "metadata": {},
    "name": "CreateSavingsPlanMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSavingsPlanMutation(\n  $input: CreateSavingsPlanInput\n) {\n  createSavingsPlan(input: $input) {\n    __typename\n    ... on SavingsPlanSignatureNeeded {\n      deepLink\n    }\n    ... on SavingsPlan {\n      id\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b72dafab815052aa4d3f496b7577d4f7";

export default node;
