import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';
import { HTMLRenderer } from '../../../HTMLRenderer/HTMLRenderer';

const TwoFaSectionTitle: FC<{ title: string }> = ({ title }) => {
  return (
    <h2
      className={css({
        fontSize: {
          base: 'headline.medium_medium',
          md: 'headline.large_semi_bold',
        },
        fontWeight: {
          base: 'headline.medium_medium',
          md: 'headline.large_semi_bold',
        },
        lineHeight: 'headline.large_semi_bold',
      })}
    >
      <HTMLRenderer>{title}</HTMLRenderer>
    </h2>
  );
};

export { TwoFaSectionTitle };
