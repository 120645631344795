import React, { forwardRef } from 'react';
import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';

type TopBarMenuButtonProps = React.PropsWithChildren<{
  isLoading?: boolean;
  ariaLabel?: string;
}>;

export const TopBarMenuButton = forwardRef<
  HTMLButtonElement,
  TopBarMenuButtonProps
>(({ children, isLoading, ariaLabel, ...rest }, ref) => {
  return (
    <button
      ref={ref}
      aria-label={ariaLabel || 'open menu button'}
      className={css({
        display: 'flex',
        alignItems: 'center',
        gap: 'extra_small_2',
        p: 'extra_small_2',
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
          backgroundColor: 'neutrals.fill_focused',
        },
        borderRadius: '4px',
        transition: 'background-color 0.3s',
      })}
      disabled={isLoading}
      {...rest}
    >
      {children}
      {isLoading ? (
        <Icon
          aria-label="loading icon"
          data-element="loading-icon"
          theme="filled"
          size="24"
          icon="spinner-ios"
        />
      ) : (
        <Icon icon="chevron-down" size="24" />
      )}
    </button>
  );
});

TopBarMenuButton.displayName = 'TopBarMenuButton';
