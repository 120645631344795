'use client';

import { useComposableModal } from '@bts-web/utils-context';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import {
  UserAccessModal,
  AppUserFullInfo,
  useClientUserInfo,
  Tag,
  BusinessUserRole,
} from '../../../common';
import {
  getReadOnlyTranslations,
  ReadOnlyTranslations,
} from '../../../layouts';

const tagStatusStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 'extra_small_4',
});

const getUserPermissionsStatusText = (
  userInfo: AppUserFullInfo,
  translations: ReadOnlyTranslations,
): string => {
  const { onboarded, approved, tradingActive, withdrawalsActive } = userInfo;

  if (onboarded && !approved && !tradingActive && withdrawalsActive) {
    return translations.tradingUnavailable;
  }

  if (onboarded && !approved) {
    return translations.pendingApproval;
  }

  return translations.readOnly;
};

export const ReadOnlyTag = () => {
  const userInfo = useClientUserInfo();

  const {
    onboarded,
    approved,
    tradingActive,
    withdrawalsActive,
    role,
    userType,
  } = userInfo;

  const { showModal } = useComposableModal();

  const { t } = useClientTranslation();

  const translations = getReadOnlyTranslations(t as (key: string) => string);

  const userPermissionsStatusText = getUserPermissionsStatusText(
    userInfo,
    translations,
  );

  if (
    userType === 'User' ||
    (role === BusinessUserRole.EXECUTION && approved)
  ) {
    return null;
  }

  const handleClick = () => {
    const { onboarded, approved, tradingActive, withdrawalsActive } = userInfo;

    if (onboarded && !approved && !tradingActive && withdrawalsActive) {
      showModal(UserAccessModal, {
        type: 'warningTrade',
      });

      return;
    }

    if (onboarded && !approved) {
      showModal(UserAccessModal, {
        type: 'infoApprovalPending',
      });

      return;
    }

    showModal(UserAccessModal, {
      type: 'readOnly',
    });
  };

  const isOutlineWarningVisual =
    (onboarded && !approved) ||
    (onboarded && !approved && !tradingActive && withdrawalsActive);

  return (
    <button onClick={handleClick} aria-label="read-only account tag">
      <Tag
        visual={
          isOutlineWarningVisual ? 'outlineWarning' : 'outlineNeutralPrimary'
        }
        size="medium"
      >
        <div className={tagStatusStyles}>
          <Icon icon="info" size="12" />
          {userPermissionsStatusText}
        </div>
      </Tag>
    </button>
  );
};
