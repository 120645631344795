import { memo } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { intlAssetValueFormatting } from '@bts-web/utils-formatting';
import { useTradeForm } from '../hooks/useTradeForm/useTradeForm';
import { TradeFormProps } from '../types';
import { TradeFormMountChecker } from '../../TradeFormMountChecker/TradeFormMountChecker.use-client';
import { DividerElement } from './DividerElement';
import { VerificationLinkButton } from '../../MainTradeVerificationLinkButton/MainTradeVerificationLinkButton';
import { AmountInputWrapper, BelowInputInfoTable } from '../../../../common';

export const VerificationLinkForm = memo(
  ({
    formState,
    staticValues,
    currentTradeDetails,
    verifyLink,
  }: {
    formState: ReturnType<typeof useTradeForm>['formState'];
    staticValues: TradeFormProps['staticValues'];
    currentTradeDetails: TradeFormProps['currentTradeDetails'];
    verifyLink: string;
  }) => (
    <>
      <TradeFormMountChecker formValue={formState.inputValue} />

      <AmountInputWrapper inputContextData={formState.inputContextData} />

      <div className={css({ pb: 'medium', pt: 'small' })}>
        <BelowInputInfoTable
          items={[
            {
              title: staticValues.translations['units'],
              value: formState.convertedSummary,
            },

            {
              title: staticValues.translations['minimum'],
              value: intlAssetValueFormatting(
                currentTradeDetails.minimumTradeAmount.FIAT?.value ?? 0,
                {
                  currency: staticValues.currency,
                  locale: staticValues.currentLocale,
                },
              ),
            },
          ]}
        />
      </div>

      <DividerElement />

      <VerificationLinkButton
        poweredByText={staticValues.translatedValues.poweredByText}
        verifyButtonText={staticValues.translatedValues.verifyButtonText}
        verificationLink={verifyLink}
        verifyTradeInfoText={staticValues.translatedValues.verifyInfoText}
      />
    </>
  ),
);
