import { getPortfolioTranslations } from '../../../portfolio/utils/getPortfolioTranslations';
import { NavigationEvents } from './navigation-events.use-client';
import { TFunction } from 'i18next';
import { initServerTranslation } from '@bts-web/utils-lokalise';

const { t } = initServerTranslation();

const translations = getPortfolioTranslations(t as TFunction);

export const NavigationEventsContainer = () => (
  <NavigationEvents translations={translations} />
);
