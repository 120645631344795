'use client';

import { getAppConfig } from '@bts-web/core-app-config';
import { FC } from 'react';
import { TwoFaSectionTitle } from '../../../shared/TwoFaSectionTitle';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TwoFaSectionButtonsWrapper } from '../../../shared/TwoFaSectionButtonsWrapper';
import { ButtonBase } from '../../../../../ButtonBase/ButtonBase';
import { css } from '@bts-web/utils-style-engine';
import { HyperLinkButtonBase } from '../../../../../HyperLinkButtonBase/HyperLinkButtonBase';

const ResetTwoFaSelectResetMethod: FC<{
  onSelectAuthenticator: () => void;
  onSelectRecovery: () => void;
  dismissLoading?: boolean;
}> = ({ onSelectAuthenticator, onSelectRecovery, dismissLoading }) => {
  const { URLforCustomerSupport } = getAppConfig();

  const { t } = useClientTranslation();

  return (
    <>
      <TwoFaSectionTitle title={`${t('2fa_reset_select_method_title')}`} />
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          isLoading={dismissLoading}
          disabled={dismissLoading}
          onClick={onSelectAuthenticator}
          visual="accent"
          fullWidth
        >
          {t('2fa_reset_option_authenticator')}
        </ButtonBase>
        <ButtonBase
          isLoading={dismissLoading}
          disabled={dismissLoading}
          visual={'secondary'}
          fullWidth
          onClick={onSelectRecovery}
        >
          {t('2fa_reset_option_recovery_code')}
        </ButtonBase>
        {URLforCustomerSupport && (
          <HyperLinkButtonBase
            visual={'ghost'}
            to={URLforCustomerSupport}
            className={css({
              textDecoration: 'underline',
            })}
          >
            {t('2fa_reset_option_helpdesk')}
          </HyperLinkButtonBase>
        )}
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { ResetTwoFaSelectResetMethod };
