import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';
import { HTMLRenderer } from '../../../HTMLRenderer/HTMLRenderer';

const TwoFaSectionDescription: FC<{ textContent: string }> = ({
  textContent,
}) => {
  return (
    <div
      className={css({
        color: 'neutrals.text_secondary',
        fontSize: 'caption.medium_medium',
      })}
    >
      <HTMLRenderer>{textContent}</HTMLRenderer>
    </div>
  );
};

export { TwoFaSectionDescription };
