import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../../common/components';

export const ListElementSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomWidth: '1px',
      borderColor: 'neutrals.stroke_secondary',
      flex: 'auto',
      minHeight: '89px',
      maxHeight: '106px',
    })}
    data-testid="list-element-skeleton"
  >
    <SkeletonElement
      width="135px"
      height="41px"
      className={css({ margin: 'small' })}
    />
    <SkeletonElement
      width="63px"
      height="41px"
      className={css({ margin: 'small' })}
    />
  </div>
);
