import { css } from '@bts-web/utils-style-engine';
import { FC, PropsWithChildren } from 'react';

const wrapperCssStyles = css({
  position: 'sticky',
  zIndex: 2,
  top: { base: 0, lg: 'calc(71px)' },
});

/**
 * `WrapperForMobileStickySectionHeader` is a functional component that provides a sticky wrapper for section headers on mobile view, used for acconting for the sticky header elements accounting for the desktop fixed header as well, used in conjunction with `AppMainLayoutWrapper`, ex: AssetsPriceHeader, AssetDetailsLayout, PortfolioSummary
 *
 * @component
 * @param {object} props - The properties that define the component's behavior and display.
 * @param {ReactNode} [props.children] - The child components to be rendered within the sticky wrapper.
 *
 * @example
 * return (
 *   <WrapperForMobileStickySectionHeader>
 *     <SectionHeader />
 *   </WrapperForMobileStickySectionHeader>
 * );
 *
 * @returns {ReactElement} The rendered `WrapperForMobileStickySectionHeader` component.
 */
export const WrapperForMobileStickySectionHeader: FC<
  PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <div
      data-testid="mobile-sticky-header"
      className={`${wrapperCssStyles} ${className ?? ''}`.trimEnd()}
    >
      {children}
    </div>
  );
};
