'use client';

import { FC } from 'react';
import { ButtonBase } from '../../../../../ButtonBase/ButtonBase';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TwoFaSectionTitle } from '../../../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../../../shared/TwoFaSectionDescription';
import { QRCode } from '../../../../../QRCode/QRCode';
import { SkeletonElement } from '../../../../../loading/SkeletonElement/SkeletonElement';
import { TwoFaSectionButtonsWrapper } from '../../../shared/TwoFaSectionButtonsWrapper';
import { css } from '@bts-web/utils-style-engine';
import { CopyToClipboardButton } from '../../../../../CopyToClipboardButton/CopyToClipboardButton';
import { ResetTwoFaCommonProps } from '../../utils/types';

const ResetTwoFaScanQrCode: FC<
  Pick<ResetTwoFaCommonProps, 'onContinue'> & {
    secretCode?: string;
    qrCode?: string;
  }
> = ({ onContinue, qrCode, secretCode }) => {
  const { t } = useClientTranslation();

  return (
    <>
      <TwoFaSectionTitle title={`${t('2fa_qr_subtitle')}`} />
      <TwoFaSectionDescription textContent={t('2fa_qr_description')} />
      {!qrCode ? (
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <SkeletonElement height="224px" width="224px" />
        </div>
      ) : (
        <QRCode
          value={qrCode}
          className={css({
            p: 0,
          })}
        />
      )}
      <TwoFaSectionDescription textContent={t('2fa_qr_manual_description')} />
      {!qrCode || !secretCode ? (
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <SkeletonElement height="28px" width="320px" />
        </div>
      ) : (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 'extra_small',
            flexWrap: 'wrap',
          })}
        >
          <code
            className={css({
              border: '1px solid',
              borderColor: 'neutrals.fill_tertiary',
              p: 'extra_small_3',
              borderRadius: '6px',
              fontSize: 'caption.medium_medium',
              flex: '1',
              minWidth: '200px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            })}
          >
            {secretCode}
          </code>
          <CopyToClipboardButton
            value={secretCode}
            ariaLabel="copy qr code url value"
          />
        </div>
      )}
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          visual="accent"
          size="large"
          fullWidth
          disabled={!qrCode || qrCode === null}
          onClick={onContinue}
          isLoading={!qrCode || qrCode === null}
        >
          {t('2fa_reset_button')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { ResetTwoFaScanQrCode };
