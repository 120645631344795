import { AmountFor } from '@bts-web/data-layer/server';
import {
  MaximumFractionDigits,
  intlAssetValueFormatting,
} from '@bts-web/utils-formatting';

export const getAssetDisplayValueFromSource = ({
  sourceType,
  currency,
  locale,
  sourceAmount,
  customSymbol,
}: {
  sourceAmount: string;
  sourceType: AmountFor;
  currency: Parameters<typeof intlAssetValueFormatting>['1']['currency'];
  locale: Parameters<typeof intlAssetValueFormatting>['1']['locale'];
  customSymbol?: string;
}) => {
  const isFiat = sourceType === 'FIAT';

  const finalMaximumFractionDigits =
    sourceType === 'ASSET'
      ? MaximumFractionDigits.ASSET
      : MaximumFractionDigits.FIAT;

  const computedValue = intlAssetValueFormatting(sourceAmount ?? '0', {
    locale: locale,
    minimumFractionDigits: isFiat ? 2 : undefined,
    maximumFractionDigits: finalMaximumFractionDigits,
    currency: isFiat ? currency : undefined,
    notation: isFiat ? 'standard' : undefined,
  });

  return `${computedValue} ${!isFiat ? (customSymbol ?? '') : ''}`;
};
