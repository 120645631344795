'use client';

import { useCallback, useMemo, useState } from 'react';

export enum TwoFaModalState {
  INITIAL_PAGE = 'initialPage',
  SCAN_QR_PAGE = 'scanQrPage',
  INPUT_CODE_PAGE = 'inputCodePage',
  RECOVERY_CODES_PAGE = 'recoveryCodesPage',
  SUMMARY_PAGE = 'summaryPage',
}

export const useTwoFaModalState = () => {
  const [currentStep, setCurrentStep] = useState<TwoFaModalState>(
    TwoFaModalState.INITIAL_PAGE,
  );

  const [recoveryCodes, setRecoveryCodes] = useState<string>('');

  const onContinue = useCallback(() => {
    switch (currentStep) {
      case TwoFaModalState.INITIAL_PAGE:
        setCurrentStep(TwoFaModalState.SCAN_QR_PAGE);

        break;

      case TwoFaModalState.SCAN_QR_PAGE:
        setCurrentStep(TwoFaModalState.INPUT_CODE_PAGE);

        break;

      case TwoFaModalState.INPUT_CODE_PAGE:
        setCurrentStep(TwoFaModalState.RECOVERY_CODES_PAGE);

        break;

      case TwoFaModalState.RECOVERY_CODES_PAGE:
        setCurrentStep(TwoFaModalState.SUMMARY_PAGE);

        break;

      default:
        break;
    }
  }, [currentStep]);

  const currentStepNumber = useMemo(() => {
    switch (currentStep) {
      case TwoFaModalState.INITIAL_PAGE:
        return 0;

      case TwoFaModalState.SCAN_QR_PAGE:
        return 1;

      case TwoFaModalState.INPUT_CODE_PAGE:
        return 2;

      case TwoFaModalState.RECOVERY_CODES_PAGE:
        return 3;

      case TwoFaModalState.SUMMARY_PAGE:
        return 4;

      default:
        return 0;
    }
  }, [currentStep]);

  return {
    setCurrentStep,
    currentStep,
    onContinue,
    currentStepNumber,
    setRecoveryCodes,
    recoveryCodes,
  };
};
