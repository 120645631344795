'use client';

import { css } from '@bts-web/utils-style-engine';
import { AcceptTermToggle } from '../../../../../../common/components/AcceptTermToggle/AcceptTermToggle.use-client';

interface AcceptOfferMetalsTermsProps {
  translations: {
    acceptMetalTerms: string;
    poweredByBpfs: string;
  };
  termsAgreed: boolean;
  setTermsAgreed: () => void;
}

const AcceptOfferMetalsTerms = ({
  translations,
  termsAgreed,
  setTermsAgreed,
}: AcceptOfferMetalsTermsProps) => {
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
        alignItems: 'flex-start',
      })}
      data-testid="buy-terms-metals"
      aria-label="buy terms metals"
    >
      <AcceptTermToggle
        termsAgreed={termsAgreed}
        onToggleCallback={setTermsAgreed}
        label={translations.acceptMetalTerms}
      />
    </div>
  );
};

export { AcceptOfferMetalsTerms };
