'use client';

import { FC, useState } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { PreAcceptSavingsPlanMutation$data } from '@bts-web/data-layer/server';
import {
  getPrecisionFloatValue,
  MaximumFractionDigits,
  PrecisionFloat,
} from '@bts-web/utils-formatting';
import { useComposableModal } from '@bts-web/utils-context';
import { isBpfsAsset } from '../utils/isBpfsAsset';
import { AssetType, GLOBAL_MAX_TRADE_AMOUNT } from '../utils';
import { PoweredByText } from '../Reusable/PoweredByText/PoweredByText';
import { TradeAssetInfoDisplay } from '../../common/components';
import { TradeScreenTranslations } from '../utils/getTradeTranslations';
import { AmountInputWrapper, ButtonBase } from '../../common';
import { BelowInputInfoTable } from '../../common/components/BelowInputInfoTable/BelowInputInfoTable';
import { acceptSavingsPlanAction } from './actions/acceptSavingsPlan.action';
import { getConvertedSummary } from '../TradeParent/utils/getConvertedSummary';
import { datadogErrorHelper } from '../../common';
import { SavingsPlanSuccessModal } from './components/SavingsPlanSuccessModal/SavingsPlanSuccessModal';
import { SavingsPlanFailureModal } from './components/SavingsPlanFailureModal/SavingsPlanFailureModal';
import { translateErrorCodesOrReturnThemDirectly } from '@bts-web/utils-relay';

export type AcceptSavingsPlanProps = {
  savingsPlanData: NonNullable<
    PreAcceptSavingsPlanMutation$data['preAcceptSavingsPlan']
  >;
  translations: TradeScreenTranslations;
  currentLocale: string;
};

export const AcceptSavingsPlan: FC<AcceptSavingsPlanProps> = ({
  translations,
  currentLocale,
  savingsPlanData,
}) => {
  const { showModal } = useComposableModal();

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const [isConfirmDone, setIsConfirmDone] = useState(false);

  const {
    logoUrl,
    name,
    priceBuy,
    symbol: initialSymbol,
    __typename,
  } = savingsPlanData.asset as NonNullable<typeof savingsPlanData.asset>;

  const symbol =
    __typename === 'MetalAsset' ? translations.grams : initialSymbol;

  const totalSum = getPrecisionFloatValue(savingsPlanData.fiatAmount);

  const isBpf = isBpfsAsset(__typename as AssetType);

  const poweredByText = {
    value: isBpf ? translations.poweredByBpfs : translations.poweredByBitpanda,
    fontSize: isBpf ? 'caption.medium_medium' : 'caption.small',
  };

  const triggerAcceptSavingsPlan = async () => {
    setIsConfirmLoading(true);

    try {
      const response = await acceptSavingsPlanAction({
        savingsPlanId: savingsPlanData.id,
      });

      setIsConfirmLoading(false);

      setIsConfirmDone(true);

      if (response.errors) {
        const { translatedCodes } =
          await translateErrorCodesOrReturnThemDirectly(response.errors);

        showModal(SavingsPlanFailureModal, {
          assetId: savingsPlanData.asset?.id as string,
          assetName: savingsPlanData.asset?.name as string,
          assetLogoUrl: savingsPlanData.asset?.logoUrl as string,
          description: translatedCodes.join(', '),
        });

        return;
      }

      if (!response.data || !response.data.acceptSavingsPlan) {
        throw new Error('server failure');
      }

      const acceptedSavingsPlanData = response.data.acceptSavingsPlan;

      showModal(SavingsPlanSuccessModal, {
        savingsPlanId: acceptedSavingsPlanData.id as string,
        assetName: acceptedSavingsPlanData.asset?.name as string,
        assetLogoUrl: acceptedSavingsPlanData.asset?.logoUrl as string,
        fiatAmount: acceptedSavingsPlanData.fiatAmount,
        name: acceptedSavingsPlanData.name as string,
        nextRecurrence: acceptedSavingsPlanData.nextRecurrence,
      });
    } catch (error) {
      showModal(SavingsPlanFailureModal, {
        assetId: savingsPlanData.asset?.id as string,
        assetName: savingsPlanData.asset?.name as string,
        assetLogoUrl: savingsPlanData.asset?.logoUrl as string,
      });

      datadogErrorHelper({
        errorMessage: JSON.stringify(error),
        errorSeverity: DatadogErrorLevels.CRITICAL,
      });
    }
  };

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        px: 'medium',
        height: '100%',
        gap: 'medium',
      })}
    >
      {/* Special Input */}
      <div>
        <TradeAssetInfoDisplay
          assetData={{ logoUrl, name }}
          currentLocale={currentLocale}
          price={savingsPlanData.asset?.priceBuy}
          symbol="EUR"
        />

        <AmountInputWrapper
          inputContextData={{
            symbol: symbol as string,
            currency: 'EUR',
            currentLocale: currentLocale,
            displayType: 'FIAT',
            value: totalSum,
            placeholder: undefined,
          }}
        />

        <div
          className={css({
            pt: 'extra_small',
            pb: 'medium',
            borderBottom: '1px solid',
            borderColor: 'neutrals.divider',
          })}
        >
          <BelowInputInfoTable
            items={[
              {
                title: translations['units'],
                value: getConvertedSummary({
                  assetPrice: priceBuy,
                  currency: 'EUR',
                  displayType: 'FIAT',
                  locale: currentLocale,
                  symbol: symbol,
                  translations: { inclSpread: translations.inclSpread },
                  value: totalSum,
                  useRawSource: {
                    rawSourceDisplayType: 'FIAT',
                    rawSourceValue:
                      savingsPlanData?.fiatAmount as PrecisionFloat,
                  },
                  maximumTradeAmount: {
                    FIAT: {
                      value: GLOBAL_MAX_TRADE_AMOUNT,
                      precision: MaximumFractionDigits.FIAT,
                    },
                    ASSET: {
                      value: GLOBAL_MAX_TRADE_AMOUNT,
                      precision: MaximumFractionDigits.ASSET,
                    },
                  },
                  showInclSpread: true,
                }),
              },
            ]}
          />
        </div>
      </div>

      <BelowInputInfoTable
        items={[
          {
            title: translations.frequency,
            value: savingsPlanData.frequency
              ? translations[savingsPlanData.frequency]
              : '',
          },
          ...(savingsPlanData.nextRecurrence
            ? [
                {
                  title: translations.startsAt,
                  value: savingsPlanData.nextRecurrence.split('T')[0],
                },
              ]
            : []),
          ...(savingsPlanData.notificationDate
            ? [
                {
                  title: translations.nextPayment,
                  value: savingsPlanData.notificationDate,
                },
              ]
            : []),
        ]}
      />

      <div
        className={css({
          pb: 'medium',
          marginTop: 'auto',
        })}
      >
        <PoweredByText {...poweredByText} />

        <ButtonBase
          data-testid="confirm-button"
          visual="accent"
          size="large"
          disabled={isConfirmLoading || isConfirmDone}
          onClick={triggerAcceptSavingsPlan}
          fullWidth
        >
          {translations.confirm}

          {isConfirmLoading && (
            <Icon
              data-element="loading-icon"
              theme="filled"
              size="20"
              icon="spinner-ios"
              aria-label="loading icon"
            />
          )}
        </ButtonBase>
      </div>
    </div>
  );
};
