import { default as i18nInstance } from 'i18next';
import {
  codeSplitBackend,
  defaultLanguage,
  translationOptions,
} from './translation-options';
import type { NamespacedTranslation } from './translation-api.type';

const initI18next = async () => {
  await i18nInstance.use(codeSplitBackend).init(translationOptions);

  return i18nInstance;
};

initI18next();

export const i18n = i18nInstance;

export async function updateServerLanguage() {
  const { cookies } = await import('next/headers');

  const language =
    (await cookies()).get('app_language')?.value || defaultLanguage;

  if (i18n.resolvedLanguage !== language) {
    return await i18n.changeLanguage(language);
  }

  // if multiple users leak the language from one to another
  // we should use per-request in-memory storage
  // or I18nextProvider
  // (https://react.i18next.com/latest/i18nextprovider)
}

export const initServerTranslation: NamespacedTranslation = (namespaceFile) => {
  return {
    t: (key, options = {}) => {
      return i18n.t(key as string, {
        ...(namespaceFile && { ns: namespaceFile }),
        ...options,
      });
    },
  };
};
