import { openTradeDrawer } from '../../../../trade/TradeDrawer/TradeProvider/utils';
import { UserAccessWithRoleCheck } from '../../../../common';
import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';

export const MobileNavTradeButton: FC<{ label: string }> = ({ label }) => {
  return (
    <UserAccessWithRoleCheck>
      <button
        onClick={openTradeDrawer}
        className={css({
          color: 'neutrals.text_primary',
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          fontSize: 'caption.small',
          justifyContent: 'center',
          backgroundColor: 'screen_background.primary',
          borderRadius: 0,
          pt: 'extra_small_3',
        })}
        aria-label="trade button"
      >
        <div
          className={css({
            backgroundColor: 'brand.fill_secondary',
            padding: 'extra_small_2',
            borderRadius: '100px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            color: 'neutrals.card_fill_primary',
          })}
        >
          <Icon size="24" icon="trade" />
        </div>

        <span
          className={css({
            lineHeight: 1,
            marginTop: 'extra_small_3',
            pb: 'extra_small_3',
          })}
        >
          {label}
        </span>
      </button>
    </UserAccessWithRoleCheck>
  );
};
