'use client';

import { useCallback, useContext, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { Icon } from '@bts-web/design-system/component/icon';
import { TradeContext } from '@bts-web/utils-context';
import { CompassIcon } from '../../../common/svgIcons/CompassIcon/CompassIcon';
import { css } from '@bts-web/utils-style-engine';
import { MobileNavItemLink } from './MobileNavItemLink.use-client/MobileNavItemLink.use-client';
import { MobileNavTradeButton } from './MobileNavTradeButton/MobileNavTradeButton';

export interface MobileNavWithTradeProps {
  labels: {
    portfolio: string;
    discover: string;
    trade: string;
  };
}

const MobileNavWithTrade = ({ labels }: MobileNavWithTradeProps) => {
  const router = useRouter();

  const { setOnClickTradeActions } = useContext(TradeContext);

  const onClickTradeSellButton = useCallback(() => {
    router.push('/assets/my-assets');
  }, [router]);

  const onClickTradeBuyButton = useCallback(() => {
    router.push('/assets/discover?next=buy');
  }, [router]);

  const onClickSavingsButton = useCallback(() => {
    router.push('/assets/discover?next=savings');
  }, [router]);

  useEffect(() => {
    setOnClickTradeActions({
      buy: onClickTradeBuyButton,
      sell: onClickTradeSellButton,
      savings: onClickSavingsButton,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={css({
        alignItems: 'flex-end',
        backgroundColor: 'screen_background.primary',
        borderTopWidth: '1px',
        borderColor: 'neutrals.divider',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: 'extra_small',
        paddingRight: 'extra_small',
        position: 'fixed',
        zIndex: 3,
        borderRadius: '16px',
        borderTop: 'none',
        boxShadow:
          '0px 10px 20px 0px rgba(0, 0, 0, 0.05), 0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
        gap: 'small',
        left: '50%',
        marginBottom: 'small',
        transform: 'translate(-50%, 0)',
        width: 'auto',
      })}
      role="navigation"
    >
      <MobileNavItemLink
        href="/assets/portfolio"
        icon={<Icon size="24" icon="portfolio" />}
        label={labels.portfolio}
      />

      <MobileNavTradeButton label={labels.trade} />

      <MobileNavItemLink
        href="/assets/discover"
        icon={<CompassIcon size={24} color="neutrals.text_primary" />}
        label={labels.discover}
      />
    </div>
  );
};

export { MobileNavWithTrade };
