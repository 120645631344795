/**
 * @generated SignedSource<<a4581057ff54c9aa6ebe287eac7f5d99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CryptoAddressDestinationTagType = "MEMO_ID" | "MEMO_TEXT";
export type ValidateCryptoAddressInput = {
  coinNetworkId: string;
  destinationTag?: string | null;
  destinationTagType?: CryptoAddressDestinationTagType | null;
  toAddress: string;
};
export type ValidateCryptoAddressQuery$variables = {
  input?: ValidateCryptoAddressInput | null;
};
export type ValidateCryptoAddressQuery$data = {
  readonly validateCryptoAddress: {
    readonly isAddressValid: boolean;
    readonly isDestinationTagValid: boolean | null;
    readonly isValid: boolean;
  } | null;
};
export type ValidateCryptoAddressQuery = {
  response: ValidateCryptoAddressQuery$data;
  variables: ValidateCryptoAddressQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ValidateCryptoAddressResponse",
    "kind": "LinkedField",
    "name": "validateCryptoAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isValid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAddressValid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDestinationTagValid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidateCryptoAddressQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValidateCryptoAddressQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2af793dfab762867bbb1e08971638b70",
    "id": null,
    "metadata": {},
    "name": "ValidateCryptoAddressQuery",
    "operationKind": "query",
    "text": "query ValidateCryptoAddressQuery(\n  $input: ValidateCryptoAddressInput\n) {\n  validateCryptoAddress(input: $input) {\n    isValid\n    isAddressValid\n    isDestinationTagValid\n  }\n}\n"
  }
};
})();

(node as any).hash = "7fd2bf140399fba80a7991eef71b14dc";

export default node;
