import { DocumentationExternalLink } from '../../../../../../common/components/DocumentationExternalLink/DocumentationExternalLink.use-client';
import { TradeScreenTranslations } from '../../../../../utils';

const LegalDocumentsTermLabel = ({
  translations,
  links,
}: {
  translations: TradeScreenTranslations;
  links: {
    derivativeContractLink: string;
    prospectusLink: string;
    keyInformationDocumentLink: string;
    investorInformationDocumentLink: string;
    costTransparencyDocumentsLink: string;
  };
}) => {
  const spaceChar = ' ';

  return (
    <>
      <span>{translations.legalTogglePartOne}</span>
      {spaceChar}
      <DocumentationExternalLink
        size="small"
        visual="primaryUnderline"
        href={links.derivativeContractLink}
        label={translations.derivativeContract}
      />
      {spaceChar}
      <span>{translations.andConfirmThatIhaveReadAndUnderstoodThe}</span>
      {spaceChar}
      <DocumentationExternalLink
        size="small"
        visual="primaryUnderline"
        href={links.prospectusLink}
        label={translations.prospectus}
      />
      {spaceChar}
      <DocumentationExternalLink
        size="small"
        visual="primaryUnderline"
        href={links.keyInformationDocumentLink}
        label={translations.keyInformationDocument}
      />
      {spaceChar}
      <DocumentationExternalLink
        size="small"
        visual="primaryUnderline"
        href={links.investorInformationDocumentLink}
        label={translations.investorInformationDocument}
      />
      {spaceChar}
      <span>{translations.and}</span>
      {spaceChar}
      <DocumentationExternalLink
        size="small"
        visual="primaryUnderline"
        href={links.costTransparencyDocumentsLink}
        label={translations.costTransparencyDocuments}
      />
      {spaceChar}
      <span>{translations.legalToggleLastPart}</span>
    </>
  );
};

export { LegalDocumentsTermLabel };
