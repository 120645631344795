import { Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/navigation';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { TGQLErrorResponseExtensionCode } from '@bts-web/utils-relay';
import { TradeInputErrorType } from '../../useTradeInput';
import { createSavingsPlanAction } from '../actions/createSavingsPlan.action';
import { datadogErrorHelper } from '../../../../../../common';
import { setWindowHistoryState } from '@bts-web/utils-functions';
import { VERIFY_LINK_KEY } from '../../../../constants';
import { applyAppropriatenessCheck } from '../../../../utils/applyAppropriatenessCheck';
import { IUseTradeForm } from '../useTradeForm';

export const checkApropriatenessAndCreateSavingsPlan = async (
  formData: FormData,
  data: {
    assetDetails: IUseTradeForm['assetDetails'];
  },
  handlers: {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setErrors: Dispatch<SetStateAction<TradeInputErrorType>>;
    processErrors: (
      gqlResponseErrors?: TGQLErrorResponseExtensionCode[],
    ) => Promise<void>;
    router: ReturnType<typeof useRouter>;
  },
) => {
  const formattedAmount = (formData.get('amount') as string).replace(/,/g, '.');

  const date = formData.get('savingsPlanRecurringDate') as string;

  await applyAppropriatenessCheck({
    asset: {
      assetType: data.assetDetails.assetType,
      assetId: data.assetDetails.assetId,
    },
    transactionType: 'SAVINGS',
  });

  try {
    const response = await createSavingsPlanAction({
      assetId: data.assetDetails.assetId,
      amount: formattedAmount,
      date,
    });

    handlers.setIsLoading(false);

    if (response.errors) {
      handlers.processErrors(response.errors);

      return;
    }

    if (!response.data || !response.data.createSavingsPlan) {
      throw new Error('server failure');
    }

    if (
      response.data.createSavingsPlan.__typename ===
      'SavingsPlanSignatureNeeded'
    ) {
      setWindowHistoryState({
        newHistoryState: {
          key: VERIFY_LINK_KEY,
          value: response.data.createSavingsPlan.deepLink,
        },
        searchParams: window.location.search,
        pathname: window.location.pathname,
      });
    }

    if (response.data.createSavingsPlan.__typename === 'SavingsPlan') {
      handlers.router.push(
        `/trade/standing-buy/accept?savingsPlanID=${response.data.createSavingsPlan.id}`,
      );
    }
  } catch (error) {
    handlers.setIsLoading(false);

    handlers.processErrors(['generic_monitored_error_description']);

    datadogErrorHelper({
      errorMessage: `[ERR][CREATE SAVINGS PLAN] ${error}`,
      errorSeverity: DatadogErrorLevels.CRITICAL,
    });
  }
};
